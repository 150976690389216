import * as React from "react";
import { createRoot } from 'react-dom/client';
import * as ReactDOM from "react-dom";

// HELPERS
import * as CountryHelper from "../react-shared/helpers/countryHelper";
import * as LabelHelper from "../react-shared/helpers/LabelHelper";

// COMPONENTS
import { LanguageSwitcher } from "./components/LanguageSwitcher";
import { CompanySearchInput } from "./components/common/form/fields/CompanySearchInput";
import { FollowCompanyButton } from "./CompanyFollow/Button";
import CheckoutPage from "./components/Checkout/index";
import { Tooltip } from "../react-shared/components/Tooltip";
import { ReportLanguageDropdown } from "./components/ReportLanguageDropdown";
import { Dropdown } from "./common/Dropdown";
import { OldReportVersionsDropdown } from "./components/OldReportVersionsDropdown";

// CONTAINERS
import { ProductsContainer } from "./containers/ProductsContainer";
import { CompanyFreeShortFinancialReport } from "./containers/CompanyFreeShortFinancialReport";
import { CompanyShortFinancialReport } from "./containers/CompanyShortFinancialReport";


const modules = {};

// CORE
registerModule("React", React);
registerModule("ReactDOM", ReactDOM);

// HELPERS
registerModule("CountryHelper", CountryHelper);
registerModule("LabelHelper", LabelHelper);

// COMPONENTS
registerModule("LanguageSwitcher", LanguageSwitcher);
registerModule("CompanySearchInput", CompanySearchInput);
registerModule("FollowCompanyButton", FollowCompanyButton);
registerModule("CheckoutPage", CheckoutPage);
registerModule("Tooltip", Tooltip)
registerModule("ReportLanguageDropdown", ReportLanguageDropdown);
registerModule("Dropdown", Dropdown);
registerModule("OldReportVersionsDropdown", OldReportVersionsDropdown);

// CONTAINERS
registerModule("ProductsContainer", ProductsContainer);
registerModule("CompanyFreeShortFinancialReport", CompanyFreeShortFinancialReport);
registerModule("CompanyShortFinancialReport", CompanyShortFinancialReport);

var removeBrowserNotSupported = document.getElementById("browser-not-supported");
if (removeBrowserNotSupported) {
    document.getElementById("browser-not-supported").remove();
}

function registerModule(name: string, component: any) {
    modules[name] = component;
}

function render(component: any, props: {}, selector: string) {
    const element = document.querySelector(selector);
    if (element) {
        const root = createRoot(element);
        root.render(React.createElement(component, props));
    }
}

function connectToReactApp(name: string, selector: string, props: {} = {}, dependecies: string[] = [], customRenderer) {
    const deps = dependecies.map(dep => modules[dep]);
    if (customRenderer && typeof customRenderer === 'function') {
        customRenderer(...deps);
    } else {
        render(modules[name], props, selector);
    }
}

window["connectToReactApp"] = connectToReactApp;
