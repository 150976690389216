import * as React from "react";
import cn from "classnames";
import { TabPanel, Tabs } from "../../common/Tabs";
import Button from "../../common/Button";
import ProductAndRegistrationForm from "./ProductAndRegistrationForm";
import ReportSummary from "./ReportSummary";
import makeApiCall from "../../Helpers/makeApiCall";
import { Gender } from "../../common/enums/gender";
import { ProductName } from "../../common/enums/product";
import { Payment } from "./Payment";
// @ts-ignore
import { debounce, pick } from "lodash";
import { ICompanySearchResult } from "../../Resources/CompanyResources";
import {
    getCurrentLanguagePath, getHref,
    getHrefWithoutQueryString, goTo,
    isBEDomain, parseQueryParams, toQueryParams
} from "../../../react-shared/helpers/locationHelper";
import { LabelCategory, LabelHelper } from "../../../react-shared/helpers/LabelHelper";
import { PaymentDropIn } from "./PaymentDropIn";

const bc = "r_checkout-page";

export interface IPaymentMethod {
    brandCode: string;
    displayImage: boolean;
    isOpen: boolean;
    methodName: string;
    subOptions: Array<{ id: string, name: string }>;
    paymentIntroTitle: string;
    paymentIntroText: string;
}

interface IReport {
    cocNumber: string;
    companyCity: string;
    companyName: string;
    currentUserProduct: number;
    defaultPriceExVat: number;
    priceExVatForCurrentUser: number;
}

interface IProps {
    paymentMethods?: IPaymentMethod[];
    reports?: IReport[];
    checkoutModel?: any;
    paymentClientKey: string;
};

const normalizeInitialFormValues = (checkoutModel: any) => ({
    ...checkoutModel,
    userType: "company",
    product: checkoutModel.product && checkoutModel.product.toString() || ProductName.NoProduct.toString(),
    companyName: checkoutModel.companyName || "",
    companyCocNumber: checkoutModel.companyCocNumber || "",
    street: checkoutModel.street || "",
    houseNumber: checkoutModel.houseNumber || "",
    postalCode: checkoutModel.postalCode || "",
    city: checkoutModel.city || "",
    firstname: checkoutModel.firstname || "",
    lastname: checkoutModel.lastname || "",
    phonenumber: checkoutModel.phonenumber || "",
    gender: checkoutModel.gender.toString() || Gender.Male.toString(),
    optIn: checkoutModel.optIn,
    termsAccepted: checkoutModel.termsAccepted || false,
    password: checkoutModel.password || "",
    email: checkoutModel.email || "",
    userIsLoggedIn: checkoutModel.userIsLoggedIn || false,
    vatNumber: checkoutModel.vatNumber || "",
    couponCode: checkoutModel.couponCode || "",
    enterpriseIds: checkoutModel.enterpriseIds || "",
    repeatPassword: "",
    functionTitle: checkoutModel.functionTitle || "",
    deliveryContentAccepted: checkoutModel.deliveryContentAccepted || false
});

const CheckoutPage: React.FunctionComponent<IProps> = (props: IProps) => {
    const [activeTab, setActiveTab] = React.useState("step1");
    const [paymentData, setPaymentData] = React.useState({} as any);
    const reports = props.reports || [];
    const [formValues, setFormValues] = React.useState(normalizeInitialFormValues(props.checkoutModel));
    const [isLoggedIn, setLoggedIn] = React.useState(props.checkoutModel.userIsLoggedIn);
    const [couponCodeProperties, setCouponCodeProperties] = React.useState({ percentage: 0, price: 0 });
    const [isLoading, setLoading] = React.useState(false);
    const [isEmailKnown, setEmailKnown] = React.useState(props.checkoutModel.userIsLoggedIn);

    const getFormDataAsync = () => {
        makeApiCall({ url: "/" + getCurrentLanguagePath() + "/checkout/GetForm/GraydonDocument" }).then(data => {
            setFormValues({
                ...formValues,
                companyName: data.companyName || "",
                companyCocNumber: data.companyCocNumber || "",
                street: data.street || "",
                houseNumber: data.houseNumber || "",
                postalCode: data.postalCode || "",
                city: data.city || "",
                firstname: data.firstname || "",
                lastname: data.lastname || "",
                phonenumber: data.phonenumber || "",
                gender: data.gender || Gender.Male,
                vatNumber: data.vatNumber || "",
                couponCode: data.couponCode || formValues.couponCode || "",
            });
        });
    };

    React.useEffect(() => {
        if (isEmailKnown && isLoggedIn) {
            getFormDataAsync();
        }
    }, [isEmailKnown, isLoggedIn]);

    const handleSubmit = (values, actions) => {
        if (isLoggedIn) {
            actions.validateForm(values).then(errors => {
                setFormValues({ ...formValues, ...values });
                setActiveTab("step2");
            });
        }
    };

    const onSubmit = debounce(() => {
        const url = "/" + getCurrentLanguagePath() + "/checkout/oneoff";
        setLoading(true);
        makeApiCall({
            url,
            method: "POST",
            asFormData: true,
            data: {
                ...pick(formValues, [
                    "priceExVat",
                    "vatPercentage",
                    "couponCode",
                    "registrationType",
                    "firstname",
                    "lastname",
                    "street",
                    "houseNumber",
                    "postalCode",
                    "city",
                    "email",
                    "password",
                    "termsAccepted",
                    "companyName",
                    "companyCocNumber",
                    "enterpriseIds",
                    "phonenumber",
                    "optIn",
                    "functionTitle",
                    "deliveryContentAccepted"
                ]),
                product: formValues.product === ProductName.NoProduct ? "" : formValues.product,
                __RequestVerificationToken: (document.querySelector("[name=__RequestVerificationToken]") as HTMLInputElement).value,
                companyName: formValues.companyName ?? "",
                companyCocNumber: formValues.companyCocNumber ?? "",
                gender: formValues.gender,
                vatNumber: formValues.vatNumber ?? ""
            }
        })
            .then(data => {
                // If price is 0 after coupon code we have to redirect to download page
                if (data.redirectTo) {
                    window.location.href = data.redirectTo;
                    return;
                }

                setPaymentData(data);
                setActiveTab("step3");
                setLoading(false);

            })
            .catch(e => {
                console.log(`Error ${url}`, e);
                setLoading(false);

            })          
    }, 500, { leading: true });

    const onCouponCodeValid = (couponCode: string, couponCodeProperties) => {
        setFormValues({
            ...formValues,
            couponCode: couponCode
        });
        setCouponCodeProperties({ ...couponCodeProperties });
    };

    const onCouponCodeInvalid = () => {
        setFormValues({
            ...formValues,
            couponCode: null
        });
        setCouponCodeProperties({ percentage: 0, price: 0 });
    };


    const onChange = (values: any) => {
        if (formValues.product === values.product) {
            return;
        }

        setFormValues({ ...values });
    };

    const onAddNewCompanyReport = (formValues: any) => (company: ICompanySearchResult) => {
        const params = parseQueryParams();
        if (!formValues.product || formValues.product.toString() === ProductName.NoProduct.toString()) {
            params["product"] = undefined;
        }
        if (formValues.product.toString() === ProductName.FiveCredit.toString()) {
            params["product"] = "FiveCredit";
        }
        if (formValues.product.toString() === ProductName.TenCredit.toString()) {
            params["product"] = "TenCredit";
        }
        if (formValues.product.toString() === ProductName.TwentyFiveCredit.toString()) {
            params["product"] = "TwentyFiveCredit";
        }
        if (formValues.product.toString() === ProductName.FiftyCredit.toString()) {
            params["product"] = "FiftyCredit";
        }
        const href = getHrefWithoutQueryString();
        const newUrl = href + `,${company.companyGraydonIdentification.graydonEnterpriseId}` + "?" + toQueryParams(params);
        goTo(newUrl);
    };

    const onRemoveReport = (report: any) => {
        const href = getHref();
        const enterpriseIds: string = formValues.enterpriseIds;
        const newEnterpriseIds = enterpriseIds.split(",").filter(it => it !== report.enterpriseId.toString()).join(",");
        goTo(href.replace(enterpriseIds, newEnterpriseIds));
    };

    return (
        <div className={cn(bc, "container")}>
            <Tabs activeTab={activeTab} onChange={() => {
            }}>
                <TabPanel
                    tabId="step1"
                    label={LabelHelper.GetLabel(LabelCategory.Checkout, "1Tab", "1. Productkeuze en registratie")}
                    className="container"
                    disabled={activeTab !== "step1"}
                >

                    <div style={{ marginTop: "50px", fontWeight: "bold" }} dangerouslySetInnerHTML={
                        {
                            __html: LabelHelper.GetLabel(LabelCategory.Checkout, "GoStopped",
                                "Vanaf 27/04/2023 wordt GraydonGo stopgezet. U kunt geen losse rapporten meer kopen. Wilt u toch gebruikmaken van bedrijfsinformatie? Bezoek <a target=\"_blank\" href=\"https://www.creditsafe.com/nl/nl/credit-risk/kredietinformatie/kredietrapporten.html?utm_source=graydongo\">onze website</a> voor meer informatie.",
                                "A partir du 27/04/2023, GraydonGo ne sera plus disponible. Vous ne pourrez plus acheter de rapports individuels. Vous souhaitez toujours utiliser les informations sur les entreprises ? Visitez <a target=\"_blank\" href=\"https://www.creditsafe.com/be/fr/gestion-du-risque/controle-de-solvabilite/rapports-de-credit.html?utm_source=graydongo\">notre site web</a> pour plus d'informations.",
                                "Vanaf 27/04/2023 wordt GraydonGo stopgezet. U kunt geen losse rapporten meer kopen. Wilt u toch gebruikmaken van bedrijfsinformatie? Bezoek <a target=\"_blank\" href=\"https://www.creditsafe.com/be/nl/risk-en-credit-management/kredietwaardigheid-controleren/kredietrapporten.html?utm_source=graydongo\">onze website</a> voor meer informatie."
                            )
                        }} ></div>
                  
                        {
                            /*
                             * 
                             
                    <ProductAndRegistrationForm
                        initialValues={formValues}
                        reports={reports}
                        onSubmit={handleSubmit}
                        isLoggedIn={isLoggedIn}
                        onChange={onChange}
                        onAddNewCompanyReport={onAddNewCompanyReport(formValues)}
                        onRemoveReport={onRemoveReport}
                        productSelection={formValues.product}
                        btwPercetage={props.checkoutModel.vatPercentage}
                        productPricesExVat={props.checkoutModel.productPricesExVat}
                        onCouponCodeValid={onCouponCodeValid}
                        onCouponCodeInvalid={onCouponCodeInvalid}
                        initialCouponCode={formValues.couponCode}
                        initialCouponCodeProperties={couponCodeProperties}
                        isLoading={isLoading}
                        isKnown={isEmailKnown}
                        displayView={props.checkoutModel.displayView}
                        onSignInSuccess={({ isKnown, email }) => {
                            setFormValues({
                                ...formValues,
                                email: email
                            });
                            setEmailKnown(isKnown as boolean);
                            setLoggedIn(true);
                        }}
                        onSignUpSuccess={({ userType, email, isKnown }) => {
                            if (!isKnown) {
                                setLoggedIn(true);
                            }
                            setEmailKnown(isKnown as boolean);
                            setFormValues({
                                ...formValues,
                                userType,
                                email: email
                            });
                        }}
                    />
                    */}
                 
                </TabPanel>

                <TabPanel
                    tabId="step2"
                    label={LabelHelper.GetLabel(LabelCategory.Checkout, "2Tab", "2. Bevestigen")}
                    disabled={activeTab !== "step2"}
                >
                    <div className="row">
                        <div className="col">
                            <ReportSummary
                                reports={reports}
                                registrationInfo={formValues}
                                btwPercetage={props.checkoutModel.vatPercentage}
                                productPricesExVat={props.checkoutModel.productPricesExVat}
                                onCouponCodeValid={onCouponCodeValid}
                                onCouponCodeInvalid={onCouponCodeInvalid}
                                initialCouponCode={formValues.couponCode}
                                initialCouponCodeProperties={couponCodeProperties}
                                displayView={props.checkoutModel.displayView}
                            />
                            <div className="btn-action-panel">
                                <Button isLoading={isLoading}
                                    label={LabelHelper.GetLabel(LabelCategory.Checkout, "ContinueBtn", "Ga verder")}
                                    type="submit" secondary wide onClick={() => { /*onSubmit() */}} disabled={true}/>
                                <Button label={LabelHelper.GetLabel(LabelCategory.Checkout, "BackBtn", "Terug")}
                                    type="button" blury rounded secondary onClick={() => setActiveTab("step1")} disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                </TabPanel>

                <TabPanel
                    tabId="step3"
                    label={LabelHelper.GetLabel(LabelCategory.Checkout, "3Tab", "3. Betaalmethode en afronding")}
                    disabled={activeTab !== "step3"}
                >
                    <PaymentDropIn {...paymentData} onBack={() => setActiveTab("step2")} />
                </TabPanel>
            </Tabs>
        </div>
    )
};
                    {/*<Payment paymentData={paymentData} onBack={() => setActiveTab("step2")}/>*/}

export default CheckoutPage;
