import * as React from "react";
import cn from "classnames";
import Card, { CardBody, CardHeader } from "../../../react-shared/components/Card";
import { LabelCategory, LabelHelper } from "../../helpers/LabelHelper";
import { Tooltip } from "../Tooltip";
import { CompanyScore, ICompanyScoreProps } from "./CompanyScore";

const bc = "company-score";

export interface ICompanyManagerAffiliation {
    firstName;
    lastName;
    typeDescription;
}

interface ICompanyBusinessStructureScoreProps extends ICompanyScoreProps {
    labelCategory: LabelCategory;
    isDefault?: boolean;
    companyManagerAffiliations: ICompanyManagerAffiliation[];
    explanation?: string;
}

const CompanyBusinessStructureScore: React.FunctionComponent<ICompanyBusinessStructureScoreProps> = ({
                                                                                                         isDefault,
                                                                                                         companyManagerAffiliations,
                                                                                                         explanation,
                                                                                                         labelCategory,
                                                                                                         ...props
                                                                                                     }) => {
    const renderBusinessStructValue = () => {
        if (isDefault) {
            return "xxxxx";
        }
        return !companyManagerAffiliations || !companyManagerAffiliations.length ? LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_Manager_NotKnown", "Niet bekend", "Pas connu") : (
            <ul className="manager-list">
                {companyManagerAffiliations.map((it, i) => <li
                    key={i}>{it.typeDescription || ""} {it.firstName} {it.lastName}</li>)}
            </ul>
        );
    };

    return (
        <CompanyScore {...props} className={cn(bc, "business-structure", props.className)}>
            <Card>
                <CardHeader>
                    <div className="score-header">
                        <h3>
                            {LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyBusinessStructureCard_HeaderText", "Bedrijfsstructuur")}
                        </h3>
                        {explanation ? <Tooltip text={explanation} className="explanation-tooltip"/> : null}
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="info-container">
                        <div className="info-container__item">
                            <span>{LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyBusinessStructureCard_Body_InfoStructureHeaderText", "Huidige bestuurders")}</span>
                            <span>{renderBusinessStructValue()}</span>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </CompanyScore>
    )
};

export default CompanyBusinessStructureScore;
