export interface IRequestVersion {
    version: number;

    up(): IRequestVersion;
}

export class RequestVersionFactory {
    static create(): IRequestVersion {
        return (() => {
            let globalVersion = 0;

            class RequestVersion implements IRequestVersion {
                constructor(public readonly version: number) {
                }

                up() {
                    if (globalVersion === Number.MAX_VALUE) {
                        globalVersion = 0;
                    }
                    globalVersion++;
                    return new RequestVersion(globalVersion);
                }
            }

            return new RequestVersion(globalVersion);
        })();
    }
}
