import * as React from "react";
import cn from "classnames";

const bc = "content-unlocker";

const ContentContext = React.createContext(null);
const ConditionsContext = React.createContext(null);

export const ContentUnlocker: React.FunctionComponent<React.PropsWithChildren<any>> = props => {
    const [isLocked, setLocked] = React.useState(true);
    const [options, setOptions] = React.useState({
        shouldHideConditionsOnUnlock: false
    });
    const lock = () => {
        setLocked(true);
    };
    const unlock = (shouldHideConditionsOnUnlock?: boolean) => {
        setLocked(false);
        setOptions({
            ...options,
            shouldHideConditionsOnUnlock: shouldHideConditionsOnUnlock ?? false
        });
    };

    return (
        <ConditionsContext.Provider value={{ lock, unlock, isLocked, options }}>
            <ContentContext.Provider value={{ isLocked, options }}>
                <div className={bc}>
                    {props.children}
                </div>
            </ContentContext.Provider>
        </ConditionsContext.Provider>
    )
};

export const ConditionsContainer: React.FunctionComponent<React.PropsWithChildren<any>> = props => {
    return (
        <ConditionsContext.Consumer>
            {({ lock, unlock, options, isLocked }) => (
                !isLocked && options.shouldHideConditionsOnUnlock
                    ? null
                    : (<div className={`${bc}__conditions`}>
                        {/* @ts-ignore */}
                        {React.Children.only(props.children) ? React.cloneElement(props.children, {
                            // @ts-ignore
                            ...props.children.props,
                            lock,
                            unlock,
                            options
                        }) : null}
                    </div>)
            )}
        </ConditionsContext.Consumer>
    )
};

export const UnlockableContent: React.FunctionComponent<React.PropsWithChildren<any>> = props => {
    return (
        <ContentContext.Consumer>
            {({ isLocked, options }) => (
                <div className={cn(`${bc}__unlockable-content`, isLocked && "locked")}>
                    {props.children}
                </div>
            )}
        </ContentContext.Consumer>
    )
};
