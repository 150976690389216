import * as React from "react";
import cn from "classnames";
import Card, { CardBody, CardFooter, CardHeader } from "../../../react-shared/components/Card";
import { LabelCategory, LabelHelper } from "../../helpers/LabelHelper";
import { Tooltip } from "../Tooltip";
import { CompanyScore, ICompanyScoreProps } from "./CompanyScore";

const bc = "company-score";

export interface IPaymentScoreProps extends ICompanyScoreProps {
    companyCountry: string;
    value: string;
    description: string;
    labelCategory: LabelCategory;
    isDefault?: boolean;
    paymentBehaviorSummonTotal?: number;
    explanation?: string;
}

const CompanyPaymentScore: React.FunctionComponent<IPaymentScoreProps> = ({
    companyCountry,
    description,
    value,
    isDefault,
    paymentBehaviorSummonTotal,
    explanation,
    labelCategory,
    ...props
}) => {
    const riskPercentage = isDefault ? 67 : (10 / 100 * +value * 100).toFixed();

    return (
        <CompanyScore {...props} className={cn(bc, "payment", props.className)}>
            <Card>
                <CardHeader>
                    <div className="score-header">
                        {companyCountry !== "nl" ?
                        
                            <h3>
                                {LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_PaymentScore_HeaderText", "Betaalscore, dagvaarding & vonnissen")}
                            </h3>
                            :
                            <h3>
                                {LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_PaymentScore_HeaderText_NLCompany", "Betaalscore")}
                            </h3>
                        }

                        {explanation ? <Tooltip text={explanation} className="explanation-tooltip" /> : null}
                    </div>
                </CardHeader>
                <CardBody contentCenter>
                    <div className="row">
                        <div className="col-medium-4">
                            <div className="digital-score-box">
                                <span>{isDefault ? "X" : value || "-"}</span>
                                {
                                    isDefault ? (
                                        <span>{LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_PaymentScore_LeftSideScoreDescription_Default", "Onder het gemiddelde")}</span>
                                    ) : (
                                        <span>{description}</span>
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-medium-8">
                            <div className="vertical-payment-score text-left">
                                <div className="vertical-payment-score__score"
                                    style={{ height: `${riskPercentage}%` }} />
                                <div className="max-score-value">
                                    <span className="text-bold">10</span>
                                    &nbsp;
                                    <span>
                                        {LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_PaymentScore_10ValueText", "Goed betaalgedrag")}
                                    </span>
                                </div>
                                <div className="min-score-value">
                                    <span className="text-bold">1</span>
                                    &nbsp;
                                    <span>
                                        {LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_PaymentScore_1ValueText", "Betaalvoorwaarden worden niet gerespecteerd")}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
                {companyCountry !== "nl" &&
                    <CardFooter contentCenter>
                        <div className="row">
                            <div className="col-medium-12">
                                <div className="digital-score-box">
                                    <span>{paymentBehaviorSummonTotal ? paymentBehaviorSummonTotal : "-"}</span>
                                    <span>{LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_PaymentScore_FooterText", "Totaal aantal dagvaardigingen en vonnissen voor dit bedrijf")}</span>
                                </div>
                            </div>
                        </div>
                    </CardFooter>
                }

            </Card>
        </CompanyScore>
    )
};

export default CompanyPaymentScore;
