import * as React from "react";
import Jumbotron from "../../common/Jumbotron";
import { ProductName } from "../../common/enums/product";
// @ts-ignore
import { Formik } from "formik";
// @ts-ignore
import cn from "classnames";
import { getProductPriceExVat, getProductPricePerReport, TotalSum } from "./TotalSum";
import { LabelCategory, LabelHelper } from "../../../react-shared/helpers/LabelHelper";
import { isNLDomain } from "../../../react-shared/helpers/locationHelper";
import { CheckoutDisplayView } from "../../common/enums/checkout-view";

const bc = "r_report-summary";

const amountReportsToHeader = {
    [ProductName.FiveCredit.toString()]: ({ productPricesExVat = 0 }) => (
        <div className={`${bc}__amount-header`}>
            <div>
                <strong>{LabelHelper.GetLabel(LabelCategory.Checkout, "5XReportHeaderSummary", "5X Financieel rapport")}</strong>
                <span className="productSummary__pdf">
                    <span className="oc-icon-report"></span>
                    <span>PDF</span>
                </span>
            </div>
        </div>
    ),

    [ProductName.TenCredit.toString()]: ({ productPricesExVat = 0 }) => (
        <div className={`${bc}__amount-header`}>
            <div>
                <strong>{LabelHelper.GetLabel(LabelCategory.Checkout, "10XReportHeaderSummary", "10X Financieel rapport")}</strong>
                <span className="productSummary__pdf"><span className="oc-icon-report"></span>
                    <span>PDF</span>
                </span>
            </div>
        </div>
    ),

    [ProductName.TwentyFiveCredit.toString()]: ({ productPricesExVat = 0 }) => (
        <div className={`${bc}__amount-header`}>
            <div>
                <strong>{LabelHelper.GetLabel(LabelCategory.Checkout, "25XReportHeaderSummary", "25X Financieel rapport")}</strong>
                <span className="productSummary__pdf"><span className="oc-icon-report"></span>
                      <span>PDF</span>
                  </span>
            </div>
        </div>
    ),

    [ProductName.FiftyCredit.toString()]: ({ productPricesExVat = 0 }) => (
        <div className={`${bc}__amount-header`}>
            <div>
                <strong>{LabelHelper.GetLabel(LabelCategory.Checkout, "50XReportHeaderSummary", "50X Financieel rapport")}</strong>
                <span className="productSummary__pdf"><span className="oc-icon-report"></span>
                      <span>PDF</span>
                  </span>
            </div>
        </div>
    ),

    [ProductName.NoProduct.toString()]: ({ reports = [] }) => (
        <div className={`${bc}__amount-header`}>
            <div>
                <strong>{reports.length}{LabelHelper.GetLabel(LabelCategory.Checkout, "SingleReportHeaderSummary", "X Financieel rapport")}</strong>
                <span className="productSummary__pdf">
                    <span className="oc-icon-report"></span>
                    <span>PDF</span>
                </span>
            </div>
            <div
                className="product-price">€{reports.reduce((acc, report) => acc += report.priceExVatForCurrentUser, 0)}</div>
        </div>
    ),

    [ProductName.ShortReport.toString()]: ({ productPricesExVat = 0 }) => (
        <div className={`${bc}__amount-header`}>
            <div>
                <strong>{LabelHelper.GetLabel(LabelCategory.Checkout, "ShortReportHeaderSummary", "Kort financieel rapport")}</strong>
            </div>
        </div>
    )
}

const restReportItems = {
    [ProductName.FiveCredit.toString()]: ({ reports = [] }) => reports.length < 5 && (
        <div className={`${bc}__rest-items`}>
            <div>{LabelHelper.GetLabel(LabelCategory.Checkout, "RestAmountOfRepports", "Resterende aantal rapporten")}</div>
            <div>{5 - reports.length} {LabelHelper.GetLabel(LabelCategory.Checkout, "RestStuksText", "Stuks")}</div>
        </div>
    ),

    [ProductName.TenCredit.toString()]: ({ reports = [] }) => reports.length < 10 && (
        <div className={`${bc}__rest-items`}>
            <div>{LabelHelper.GetLabel(LabelCategory.Checkout, "RestAmountOfRepports", "Resterende aantal rapporten")}</div>
            <div>{10 - reports.length} {LabelHelper.GetLabel(LabelCategory.Checkout, "RestStuksText", "Stuks")}</div>
        </div>
    ),

    [ProductName.TwentyFiveCredit.toString()]: ({ reports = [] }) => reports.length < 25 && (
        <div className={`${bc}__rest-items`}>
            <div>{LabelHelper.GetLabel(LabelCategory.Checkout, "RestAmountOfRepports", "Resterende aantal rapporten")}</div>
            <div>{25 - reports.length} {LabelHelper.GetLabel(LabelCategory.Checkout, "RestStuksText", "Stuks")}</div>
        </div>
    ),

    [ProductName.FiftyCredit.toString()]: ({ reports = [] }) => reports.length < 50 && (
        <div className={`${bc}__rest-items`}>
            <div>{LabelHelper.GetLabel(LabelCategory.Checkout, "RestAmountOfRepports", "Resterende aantal rapporten")}</div>
            <div>{50 - reports.length} {LabelHelper.GetLabel(LabelCategory.Checkout, "RestStuksText", "Stuks")}</div>
        </div>
    ),

    [ProductName.NoProduct.toString()]: () => null,

    [ProductName.ShortReport.toString()]: () => null
};

const customerInfo = {
    "personal": (registrationInfo = {} as any) => (
        <div className={`${bc}__customer-info`}>
            <div>{registrationInfo.firstname} {registrationInfo.lastname}</div>
            <div>{registrationInfo.street} {registrationInfo.houseNumber}</div>
            <div>{registrationInfo.postalCode} {registrationInfo.city}</div>
        </div>
    ),
    "company": (registrationInfo = {} as any) => (
        <div className={`${bc}__customer-info`}>
            <div>{registrationInfo.companyName}</div>
            <div>T.a.v.: {registrationInfo.firstname} {registrationInfo.lastname}</div>
            <div>{registrationInfo.street} {registrationInfo.houseNumber}</div>
            <div>{registrationInfo.postalCode} {registrationInfo.city}</div>
            {isNLDomain() && registrationInfo.vatNumber && <div>
                <strong>{LabelHelper.GetLabel(LabelCategory.Checkout, "BTWNumberLable", "BTW number")}</strong> {registrationInfo.vatNumber}
            </div>}
        </div>
    )
};

const ReportSummary: React.FunctionComponent<any> = ({
                                                         reports = [],
                                                         registrationInfo = {},
                                                         btwPercetage,
                                                         productPricesExVat,
                                                         onCouponCodeValid = (couponCode: string, couponCodeProperties) => {
                                                         },
                                                         onCouponCodeInvalid = () => {
                                                         },
                                                         initialCouponCode = "",
                                                         initialCouponCodeProperties = { Percentage: 0, Price: 0 },
                                                         displayView
                                                     }) => {
    const { product } = registrationInfo;
    const reportPrice = getProductPricePerReport(getProductPriceExVat(productPricesExVat, product), product);


    return (
        <div className={bc}>
            <h3>{LabelHelper.GetLabel(LabelCategory.Checkout, "ConfirmOrderHeader", "Bevestig uw bestelling")}:</h3>
            <Jumbotron>
                {amountReportsToHeader[product]({
                    reports,
                    productPricesExVat: getProductPricePerReport(getProductPriceExVat(productPricesExVat, product), product)
                })}

                {(() => {
                    switch (displayView) {
                        default:
                        case CheckoutDisplayView.Regular:
                            return (
                                <>
                                    <a>{LabelHelper.GetLabel(LabelCategory.Checkout, "RepportSummaryDonloadedText", "Volgende rapport(en) direct te downloaded na betaling:")}</a>
                                    {reports.map(report => (
                                        <div className="product-price-div" style={{ marginTop: "10px" }}
                                             key={report.companyName}>
                                            <div>
                                                <strong>{LabelHelper.GetLabel(LabelCategory.Checkout, "HeadFinanciesReportSection", "Financieel rapport")}</strong>
                                                <span className="productSummary__pdf"><span
                                                    className="oc-icon-report"></span><span>PDF</span></span><br/>
                                                <span>{report.companyName} {LabelHelper.GetLabel(LabelCategory.Checkout, "ConjunctionBetweenCompanyNameAndCompanyCityInReportSummary", "te")} {report.companyCity}</span>
                                                <span className="price-per-report">€{reportPrice}</span>
                                            </div>
                                            <br/>
                                        </div>
                                    ))}
                                    {restReportItems[product]({ reports })}
                                </>
                            );

                        case CheckoutDisplayView.ShortReportOnly:
                            return (
                                <>
                                    {reports.map(report => (
                                        <div className="product-price-div" style={{ marginTop: "10px" }}
                                             key={report.companyName}>
                                            <div>
                                                <span>{report.companyName} {LabelHelper.GetLabel(LabelCategory.Checkout, "ConjunctionBetweenCompanyNameAndCompanyCityInReportSummary", "te")} {report.companyCity}</span>
                                                <br/>
                                                <span className="price-per-report">€{reportPrice}</span>
                                            </div>
                                            <br/>
                                        </div>
                                    ))}
                                </>
                            )
                    }
                })()}
            </Jumbotron>

            <TotalSum
                reportCount={reports.length}
                productSelection={product}
                btwPercetage={btwPercetage}
                productPricesExVat={productPricesExVat}
                initialCouponCode={initialCouponCode}
                initialCouponCodeProperties={initialCouponCodeProperties}
            />

            <h3>{LabelHelper.GetLabel(LabelCategory.Checkout, "PaymentInfoHeader", "Factuurgegevens")}:</h3>
            <span
                className={`${bc}__paymentinfo_sub-header`}>{LabelHelper.GetLabel(LabelCategory.Checkout, "PaymentInfoHeader_sub", "Bekijk onderstaande gegevens en bevestig of deze correct zijn. U vindt uw factuur en bedrijfsrapport steeds terug in ‘Mijn Account’.")}</span>
            {customerInfo[registrationInfo.userType](registrationInfo)}
        </div>
    )
};
ReportSummary.defaultProps = {
    displayView: CheckoutDisplayView.Regular
};

export default ReportSummary;
