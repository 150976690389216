import * as React from "react";
import Card, { CardBody, CardFooter, CardHeader } from "../../components/Card";
import CompanyGradeChart, { CompanyGrade } from "./CompanyGradeChart";
import { LabelCategory, LabelHelper } from "../../helpers/LabelHelper";
import { Tooltip } from "../Tooltip";
import { bc, CompanyScore, ICompanyScoreProps } from "./CompanyScore";
import cn from "classnames";

export interface ICompanyPaymentRatingAndPercentageScoreProps extends ICompanyScoreProps {
    grade: CompanyGrade;
    percentage: number;
    labelCategory: LabelCategory;
    isDefault?: boolean;
    explanation?: string;
    mode?: "bar" | "half-pie" | "half-pie-group";
    hideLegend?: boolean;
    multiScoreValue?: string;
}

const CompanyPaymentRatingAndPercentageScore: React.FunctionComponent<ICompanyPaymentRatingAndPercentageScoreProps> = ({
                                                                                                                           grade,
                                                                                                                           percentage,
                                                                                                                           isDefault,
                                                                                                                           explanation,
                                                                                                                           mode,
                                                                                                                           hideLegend,
                                                                                                                           labelCategory,
                                                                                                                           className,
                                                                                                                           multiScoreValue,
                                                                                                                           ...props
                                                                                                                       }) => {
    const renderDefaultPercentageText = () => "X %";

    return (
        <CompanyScore {...props} className={cn("payment-rating-and-percentage", className && className)}>
            <Card>
                <CardHeader>
                    <div className="score-header">
                        <h3>
                            {LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_GraydonRatingAndPercentage_CardHeader", "Graydon rating & percentage kans op faillissement")}
                        </h3>
                        {explanation ? <Tooltip text={explanation} className="explanation-tooltip"/> : null}
                    </div>
                </CardHeader>
                <CardBody contentCenter>
                    <CompanyGradeChart labelCategory={labelCategory} mode={mode} hideLegend={hideLegend} grade={grade} multiScoreValue={multiScoreValue}
                                       isDefault={isDefault}/>
                </CardBody>
                <CardFooter contentCenter>
                    <div className="percentage-score">
                        <span
                            className="percentage">{`${isDefault ? renderDefaultPercentageText() : percentage ? `${percentage} %` : "X %"}`}</span>
                        <span
                            className="percentage-description">{LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyRatingAndPercentageDescription", "Percentage kans op faillissement", "Pourcentage de probabilité de faillite")}</span>
                    </div>
                </CardFooter>
            </Card>
        </CompanyScore>
    )
};

export default CompanyPaymentRatingAndPercentageScore;
