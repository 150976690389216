import * as React from "react";
import cn from "classnames";

interface ITabsProps {
    onChange: (selectedTabProps: ITabPanelProps) => void,
    className?: string;
    activeTab: string;
};

interface ITabPanelProps {
    tabId: string;
    label: string;
    className?: string;
    disabled?: boolean;
    isActive?: string;
}

const bc = "r_tabs";

const render = callback => (children: any) => {
    return React.Children.map(children, child => child.type.displayName === "TabPanel"
        ? callback(child)
        : child)
};

const Tabs: React.FunctionComponent<React.PropsWithChildren<ITabsProps>> = ({ className = "", activeTab, onChange = () => { }, children }) => {
    const [active, setActive] = React.useState(activeTab);

    React.useEffect(() => {
        setActive(activeTab);
    }, [activeTab]);

    const labels = [];

    const onClick = (props: ITabPanelProps) => {
        if (props.disabled) {
            return;
        }

        setActive(props.tabId);
        onChange({ ...props });
    };

    const renderedBody = render(children => {
        const patchedChildren = React.cloneElement(children, {
            ...children.props,
            isActive: active === children.props.tabId
        });
        labels.push(patchedChildren);
        return patchedChildren;
    })(children);

    return (
        <div className={cn(bc, className)}>
            <div className="tab-nav">
                {React.Children.map(labels, child => (
                    <div
                        className={cn("tab-label", child.props.isActive && !child.props.disabled && "active", child.props.disabled && "disabled")}
                        onClick={() => onClick(child.props)}
                    >
                        {child.props.label}
                    </div>
                    ))}
            </div>
            {renderedBody}
        </div>
        )
};
Tabs.displayName = "Tabs";

const TabPanel: React.FunctionComponent<React.PropsWithChildren<ITabPanelProps>> = ({ children, tabId, className = "", disabled, isActive }) => {
    return isActive && (
        <div className={cn(bc, "tab-panel", disabled && "disabled", className)} data-tab-id={tabId}>
            {children}
        </div>
        )
};
TabPanel.displayName = "TabPanel";

export {
    Tabs,
    TabPanel
}
