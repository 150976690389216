import * as React from "react";
import * as _ from "underscore";
import cn from "classnames";
import makeApiCall from "../../../../Helpers/makeApiCall";
import CompanySearchFlagInput from "./CompanySearchFlagInput";
import CompanySearchResultItem from "./CompanySearchResultItem";
import { LabelCategory, LabelHelper } from "../../../../../react-shared/helpers/LabelHelper";
import { cacheCountry, getCountry } from "../../../../../react-shared/helpers/countryHelper";
import { Spinner } from "../../../../common/Spinner";
import { IRequestVersion, RequestVersionFactory } from "../../../../Helpers/RequestVersion";

const bc = "form__company-search";

interface IProps {
    action?: string;
    allowAction?: boolean;
    label?: string;
    placeholder?: string;
    size?: "big" | "small";
    forAmount?: number;
    withHint?: boolean;
}

interface IState {
    search: string;
    code: string;
    isSearching?: boolean;
    results: ICompanySearchResult[];
    noSearch: boolean;
    isOpen: boolean;
    requestVersion?: IRequestVersion;
}

export interface ICompanySearchResult {
    companyGraydonProfile: ICompanySearchProfile;
    companyGraydonIdentification: ICompanySearchIdentification;
}

export interface ICompanySearchProfile {
    companyName: string;
    isActive: boolean;
    city: string;
}

export interface ICompanySearchIdentification {
    registrationId: string;
    graydonEnterpriseId: number;
    countryCode: string;
    isBranchOffice: boolean;
}

const requestVersion = RequestVersionFactory.create();

// TODO: Config build!
// TODO: Rewrite on react hooks instead

/**
 * It has two input with names: country and search.
 * Map initial values to this input names.
 * */
export class CompanySearchInput extends React.PureComponent<IProps, IState> {
    static defaultProps = {
        size: "big",
        forAmount: 1
    };

    componentDidMount() {
        document.addEventListener("click", this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
    }

    handleClickOutside = e => {
        if (!e.target.closest("[data-search-component]")) {
            this.setOpen(false);
        }
    };

    state = {
        search: "",
        code: getCountry(),
        results: [],
        isSearching: false,
        isOpen: false
    } as IState;

    get availableCountries(): ICompanySearchResult[] {
        return this.state.results || [];
    }

    request = (search: string, countryCode = "nl", requestVersion: IRequestVersion) => {
        makeApiCall({ url: `/company-search/${countryCode}?q=${encodeURIComponent(search)}` })
            .then(response => {
                if (this.state.requestVersion.version === requestVersion.version) {
                    this.setState({
                        ...this.state,
                        results: response || [],
                        isSearching: null,

                    });
                }
            })
            .catch(() => {
                this.setState({
                    ...this.state,
                    results: [],
                    isSearching: null
                });
            });
    }

    onChange = (fieldName: string, onCallBack: any = null) => ({ target: { value } }) => {
        if (fieldName === "code") {
            cacheCountry(value);
        }

        this.setState({
            ...this.state,
            [fieldName]: value,
            isSearching: false,
            results: []
        }, () => { if (onCallBack) { onCallBack(); } });
    }

    search = () => {
        if (this.state.search) {
            const newRequestVersion = requestVersion.up();             

            this.setState({
                ...this.state,
                isSearching: true,
                isOpen: true,
                results: [],
                requestVersion: newRequestVersion
            });

            this.getData(this.state.search, this.state.code, newRequestVersion);

        }

    }

    setOpen = (value: boolean) => {
        this.setState(state => ({ ...state, isOpen: value }));
    }

    handleClick = () => {
        this.setOpen(true);
    };

    getData = _.debounce(this.request, 500);

    renderResults() {
        return this.state.search && !this.state.isSearching && (
            <ul className="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front company-search-result-items">
                {
                    this.state.results.length
                        ? this.availableCountries.map((it, i) => (
                            <li className="ui-menu-item" key={it.companyGraydonProfile.companyName + i}>
                                <CompanySearchResultItem
                                    companyIdentification={it.companyGraydonIdentification}
                                    companyProfile={it.companyGraydonProfile}
                                    highLightedWord={this.state.search}
                                    forAmount={this.props.forAmount}
                                />
                            </li>
                        ))
                        : <li className="autocomplete__feedback">{this.state.isSearching == null ? LabelHelper.GetLabel(LabelCategory.CompanySearch, "SearhingHint-NoResults", "Geen resultaten", "Aucun résultat", "Geen resultaten", "No results") :
                                                                                                   LabelHelper.GetLabel(LabelCategory.CompanySearch, "SearhingHint-PressEnterToSearch", "Druk ENTER om te zoeken", "Appuyez sur ENTER pour rechercher", "Druk ENTER om te zoeken", "Press ENTER to search")}</li>
                }
            </ul>
        )
    }

    renderSearching() {
        return (
            <ul className="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front">
                <li className="autocomplete__feedback">{LabelHelper.GetLabel(LabelCategory.CompanySearch, "SearhingHint", "...Zoeken")}</li>
            </ul>
        )
    }

    onSubmit = (e) => {
        if (!this.props.allowAction) {
            e.preventDefault();
        }
    };

    render() {
        return (
            <div className={cn(bc, this.props.size, this.props.size === "small" && "search-company-small")} data-action>
                <form action={this.props.action} autoComplete="off" onSubmit={this.onSubmit}>
                    <CompanySearchFlagInput
                        name="code"
                        value={this.state["code"]}
                        renderOption={(item) => <div><img src={item.src} /></div>}
                        renderSelectedOption={(item) => <div><img src={item.src} alt={item.alt} /></div>}
                        onChange={this.onChange("code", this.search)}
                        withHint={this.props.withHint}
                        
                    />

                    <div data-search-component className="search-bar--large" style={{ width: "100%" }}>
                        <input
                            autoComplete="off"
                            onKeyUp={(e) => { if (e.keyCode == 13) { this.search() } }}
                            onChange={this.onChange("search") }
                            value={this.state["search"]}
                            type="search"
                            placeholder={this.props.placeholder}
                            name="search"
                            className="form__field search-bar__input--large ui-autocomplete-input"
                            onClick={this.handleClick}
                        />
                        <button type="button" onClick={this.search}
                                className={`btn--search--large btn--secondary btn--icon ${this.state.isSearching ? "" : "oc-icon-search"}`}>
                            {this.state.isSearching && <Spinner className="search-spinner"/>}
                        </button>
                        {this.state.isSearching && this.state.isOpen && this.renderSearching()}
                        {this.state.isOpen && this.renderResults()}
                    </div>
                    {this.state.code == "gb" &&
                        <div className="search-uk-warning" dangerouslySetInnerHTML={{
                            __html: LabelHelper.GetLabel(LabelCategory.CompanySearch, "Search-UK-Warning", "Vanaf 31/03/2023 23u00 biedt GraydonGo geen UK rapporten meer aan. Indien u interesse heeft in internationale bedrijfsinformatie, kunt u contact opnemen via <a href=\"mailto:gyd-support@graydoncreditsafe.be\">gyd-support@graydoncreditsafe.be</a>.", "À compter du 31/03/2023 23h00, GraydonGo ne proposera plus de rapports d'entreprises pour le UK. Si vous êtes intéressé(e)s par des informations commerciales internationales, merci de nous contacter par <a href=\"mailto:gyd-support@graydoncreditsafe.be\">gyd-support@graydoncreditsafe.be</a>.", "Vanaf 31/03/2023 23u00 biedt GraydonGo geen UK rapporten meer aan. Indien u interesse heeft in internationale bedrijfsinformatie, kunt u contact opnemen via <a href=\"mailto:gyd-support@graydoncreditsafe.be\">gyd-support@graydoncreditsafe.be</a>.")
                            
                            }}>
                        </div>}

                </form>
            </div>
        )
    }
}
