import * as React from "react";
import { LabelCategory, LabelHelper } from "../../react-shared/helpers/LabelHelper";
import { CompanyGrade } from "../../react-shared/components/CompanyProfileScore/CompanyGradeChart";
import CompanyPaymentRatingAndPercentageScore
    from "../../react-shared/components/CompanyProfileScore/CompanyPaymentRatingAndPercentageScore";
import CompanyPaymentScore from "../../react-shared/components/CompanyProfileScore/CompanyPaymentScore";
import CompanyCreditLimitScore from "../../react-shared/components/CompanyProfileScore/CompanyCreditLimitScore";
import CompanyInfoExtScore from "../../react-shared/components/CompanyProfileScore/CompanyInfoExtScore";
import CompanyBusinessStructureScore, {
    ICompanyManagerAffiliation
} from "../../react-shared/components/CompanyProfileScore/CompanyBusinessStructureScore";
import CompanyKeyFigureScore from "../../react-shared/components/CompanyProfileScore/CompanyKeyFigureScore";

const bc = "financial-profile-container";

interface IProps {
    isOpenProfile: boolean;
    isExampleReport?: boolean;
    companyCountryCode: string,
    graydonRatingScore: {
        grade: CompanyGrade;
        percentage: number;
        multiScoreValue?: string;
    },
    paymentScore?: {
        value: string;
        description: string;
        paymentBehaviorSummonTotal?: number;
    },
    companyCreditLimitScore?: {
        value: number;
        currencyCode: string;
    },
    companyInfoExtScore?: {
        foundationDate?: Date;
        numberOfBranches?: number;
        companyRegistrationId?: string;
        companyEnterpriseId?: number;
        legalFormDescription?: string;
        activitiesText?: string;
        webSiteUrl?: string;
        employeeTeamClassification?: string;
    },
    businessStructureScore?: {
        companyManagerAffiliations: ICompanyManagerAffiliation[];
    },
    keyFigureScore?: {
        currency: string;
        year: string;
        balance: Record<string, number>;
        incomeStatement: Record<string, number>;
        ratios: Record<string, number>;
    }
}

export const CompanyShortFinancialReport: React.FunctionComponent<IProps> = props => {
    return (
        <div className={bc}>
            {props.isExampleReport
                ? (
                    <div className="example-report-watermark">
                        {LabelHelper.GetLabel(LabelCategory.CompanyProfile, "CompanyProfilePage_ExampleReport_Watermark", "Voorbeeld rapport", "Exemple de rapport", "Voorbeeld rapport", "Example report")}
                    </div>
                )
                : null}
            <div className="row">
                <div className="col-medium-4">
                    <CompanyPaymentScore
                        companyCountry={props.companyCountryCode}
                        isDefault={!props.paymentScore}
                        disabled={!props.paymentScore}
                        labelCategory={LabelCategory.CompanyProfile}
                        description={props.paymentScore?.description}
                        value={props.paymentScore?.value}
                        paymentBehaviorSummonTotal={props.paymentScore?.paymentBehaviorSummonTotal}
                        explanation={
                            props.companyCountryCode !== "nl" ?
                                LabelHelper.GetLabel(LabelCategory.KBCIntegration, "CompanyProfilePage_PaymentScore_ExplanationTooltipText", "De betaalscore vertelt u hoe groot de kans is dat uw klant de afgesproken betaaltermijn zal respecteren. Hoe hoger de betaalscore (op een schaal van 0 tot 10), hoe beter het betaalgedrag. Het aantal dagvaardingen geeft aan hoe vaak een bedrijf gedagvaard werd door de Rijksdienst voor Sociale Zekerheid, het Sociaal Verzekeringfonds voor Zelfstandigen of het Fonds voor Bestaanszekerheid. Open het overzicht voor meer details.") :
                                LabelHelper.GetLabel(LabelCategory.KBCIntegration, "CompanyProfilePage_PaymentScore_ExplanationTooltipText_NLCompany", "De betaalscore vertelt u hoe groot de kans is dat uw klant de afgesproken betaaltermijn zal respecteren. Hoe hoger de betaalscore (op een schaal van 0 tot 10), hoe beter het betaalgedrag. ")
                        }
                    />
                </div>
                <div className="col-medium-5">
                    <CompanyPaymentRatingAndPercentageScore
                        isDefault={!props.graydonRatingScore}
                        disabled={!props.graydonRatingScore}
                        labelCategory={LabelCategory.CompanyProfile}
                        grade={props.graydonRatingScore?.grade}
                        mode={props.isOpenProfile ? "bar" : "half-pie-group"}
                        percentage={props.graydonRatingScore?.percentage}
                        multiScoreValue={props.graydonRatingScore?.multiScoreValue}
                        explanation={LabelHelper.GetLabel(LabelCategory.CompanyProfile, "CompanyProfilePage_GraydonRatingAndPercentage_ExplanationTooltipText", "De Graydon Rating toont de mate van kredietrisico aan. De AAA-rating is de hoogste rating en wijst op het laagst mogelijke risico. De C-rating is de zwakste rating en wijst op een zeer hoog rsicio. Bedrijven met een rating D verkeren in een staat van faillissement. NR betekent dat er geen rating vastgelegd kon worden omdat het bedrijf passief is. De kans op faillissement geeft aan hoe groot het risico is dat het bedrijf ernstige financiële problemen ondervindt en failliet gaat. Hoe lager het percentage hoe lager het risico.")}
                    />
                </div>
                <div className="col-medium-3">
                    <CompanyCreditLimitScore
                        isDefault={!props.companyCreditLimitScore}
                        disabled={!props.companyCreditLimitScore}
                        labelCategory={LabelCategory.CompanyProfile}
                        value={props.companyCreditLimitScore?.value}
                        currencyCode={props.companyCreditLimitScore?.currencyCode}
                        explanation={LabelHelper.GetLabel(LabelCategory.KBCIntegration, "CompanyProfilePage_CreditLimitCardHeader_ExplanationTooltipText", `Credit flag<br/>
De credit flag werkt als een stoplicht, en is kortgezegd een simpele vertaling van het kredietadvies en de Graydon Rating.Groen = kredietverlening levert geen problemen op.Oranje = krediet verlenen gaat gepaard met een verhoogd risico.Rood = Graydon raadt af om aan voorfinanciering te doen.
<br/></br>
Credit limit
<br/>
De kredietlimiet geeft aan tot welk bedrag het verantwoord is om met een bedrijf zaken te doen.De kredietlimiet vorm een waardeoordeel op korte termijn.`)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-medium-9">
                    <CompanyInfoExtScore
                        {...props.companyInfoExtScore}
                        labelCategory={LabelCategory.CompanyProfile}
                        isDefault={!props.companyInfoExtScore}
                        disabled={!props.companyInfoExtScore}
                        explanation={LabelHelper.GetLabel(LabelCategory.KBCIntegration, "CompanyProfilePage_CompanyInfoExtScore_ExplanationTooltipText", "Hier vindt u kort de belangrijkste basisgegevens voor het bedrijf. Open het overzicht voor meer details.")}
                    />
                </div>
                <div className="col-medium-3">
                    <CompanyBusinessStructureScore
                        isDefault={!props.businessStructureScore}
                        disabled={!props.businessStructureScore}
                        labelCategory={LabelCategory.CompanyProfile}
                        companyManagerAffiliations={props.businessStructureScore?.companyManagerAffiliations}
                        explanation={LabelHelper.GetLabel(LabelCategory.KBCIntegration, "CompanyProfilePage_CompanyBusinessStructureScore_ExplanationTooltipText", "In dit gedeelte vindt u meer informatie over de bedrijfsstructuur, met onder andere de (ultieme) moedermaatschappij. Open het overzicht om de bedrijfsleiders, de aandeelhouders of de participaties te consulteren.")}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-medium-12">
                    <CompanyKeyFigureScore
                        {...props.keyFigureScore}
                        isDefault={!props.keyFigureScore}
                        disabled={!props.keyFigureScore}
                        labelCategory={LabelCategory.CompanyProfile}
                        explanation={LabelHelper.GetLabel(LabelCategory.KBCIntegration, "CompanyProfilePage_CompanyKeyFigureScore_ExplanationTooltipText", "Hier krijgt u in het kort een overzicht van de belangrijkste cijfers uit de meest recente jaarrekening en een aantal financiële ratio's. Open het overzicht voor meer kerncijfers. Of om de balans en de resultatenrekening van de laatste 5 boekjaren te consulteren.")}
                    />
                </div>
            </div>
        </div>
    )
};
