export enum ProductName {
    NoProduct = "NoProduct",
    Basic = "Basic",
    Premium = "Premium",
    Corporate = "Corporate",
    OpenCompany = "OpenCompany",
    Pro = "Pro",
    ProPlus = "ProPlus",
    FiveCredit = "FiveCredit",
    TenCredit = "TenCredit",
    TwentyFiveCredit = "TwentyFiveCredit",
    FiftyCredit = "FiftyCredit",
    ShortReport = "ShortReport"
}
