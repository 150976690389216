import * as React from "react";
import cn from "classnames";
import Card, { CardBody, CardHeader } from "../../../react-shared/components/Card";
import { LabelCategory, LabelHelper } from "../../helpers/LabelHelper";
import { Tooltip } from "../Tooltip";
import moneyFormatter from "../../../react-shared/helpers/moneyFormatter";
import { CompanyScore, ICompanyScoreProps } from "./CompanyScore";

const bc = "company-score";

interface IProps extends ICompanyScoreProps {
    currency: string;
    year: string;
    balance: Record<string, number>;
    incomeStatement: Record<string, number>;
    ratios: Record<string, number>;
    labelCategory: LabelCategory;
    isDefault?: boolean;
    explanation?: string;
}

const CurrencyCodeDict = {
    "EUR": "€"
};

const DEFAULT_INCOME_STATEMENT_DICT: Record<string, number> = {
    "KeyFigures_Default_Cash": 0,
    "KeyFigures_Default_WorkingCapital": 0
};
const DEFAULT_BALANCE_DICT: Record<string, number> = {
    "KeyFigures_Default_Capital": 0,
    "KeyFigures_Default_Total_Equity": 0,
    "KeyFigures_Default_Cashflow": 0
};
const DEFAULT_RATIO_DICT: Record<string, number> = {
    "KeyFigures_Default_CurrentRation": 0,
    "KeyFigures_Default_QuickRatio": 0,
};

const CompanyKeyFigureScore: React.FunctionComponent<IProps> = ({
                                                                    isDefault,
                                                                    currency,
                                                                    year,
                                                                    ratios,
                                                                    incomeStatement,
                                                                    balance,
                                                                    explanation,
                                                                    labelCategory,
                                                                    ...props
                                                                }) => {
    const renderHeaderYearValue = () => {
        if (isDefault) {
            return new Date().getFullYear();
        }
        return year ? year : "-";
    };
    const renderHeaderModelValue = () => {
        if (isDefault) {
            return "xxxxx";
        }
        return "-"; // TODO: Find API for this
    };
    const renderCurrencyValue = () => {
        if (isDefault) {
            return "x €1";
        }
        return CurrencyCodeDict[currency] ? `x ${CurrencyCodeDict[currency]}1` : "-";
    };

    const balanceDict = isDefault ? DEFAULT_BALANCE_DICT : balance;
    const incomeStatementDict = isDefault ? DEFAULT_INCOME_STATEMENT_DICT : incomeStatement;
    const ratiosDict = isDefault ? DEFAULT_RATIO_DICT : ratios;

    return (
        <CompanyScore {...props} className={cn(bc, "key-figure", props.className)}>
            <Card>
                <CardHeader>
                    <div className="score-header">
                        <h3>
                            {LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyKeyFigureCard_HeaderText", "Kerncijfers")}
                        </h3>
                        {explanation ? <Tooltip text={explanation} className="explanation-tooltip"/> : null}
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="first-section row">
                        <div className="col-medium-6">
                            <div className="row info-container">
                                <div className="col-small-6 info-container__item">
                                    <span>{LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyKeyFigureCard_FiledOnYearText", "Gedeponeerd op")}</span>
                                    <span>{renderHeaderYearValue()}</span>
                                </div>
                                <div className="col-small-6 info-container__item">
                                    <span>{LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyKeyFigureCard_ModelText", "Model")}</span>
                                    <span>{renderHeaderModelValue()}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="second-section row">
                        <div className="col-medium-6">
                            <div className="table-list">
                                <div className="table-list__head">
                                    <div>
                                        {LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyKeyFigureCard_ListHeader1", "Basisvaluta en eenheid")}
                                    </div>
                                    <div>
                                        <span className="year">{renderHeaderYearValue()}</span>
                                        <span className="currency-amount">({renderCurrencyValue()})</span>
                                    </div>
                                </div>
                                <div className="table-list__body">
                                    {
                                        Object.keys(incomeStatementDict).map(key => (
                                            <div className="table-list__body-row" key={key}>
                                                <div className="table-list__body-col">
                                            <span className="description">
                                                {LabelHelper.GetLabel(labelCategory, key, key)}
                                            </span>
                                                    <span
                                                        className="cost">{isDefault ? "xxxxx" : moneyFormatter(incomeStatementDict[key])}</span>
                                                </div>
                                            </div>
                                        ))
                                    }

                                    {
                                        Object.keys(balanceDict).map(key => (
                                            <div className="table-list__body-row" key={key}>
                                                <div className="table-list__body-col">
                                            <span className="description">
                                                {LabelHelper.GetLabel(labelCategory, key, key)}
                                            </span>
                                                    <span
                                                        className="cost">{isDefault ? "xxxxx" : moneyFormatter(balanceDict[key])}</span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-medium-6">
                            <div className="table-list">
                                <div className="table-list__head">
                                    <div>
                                        &nbsp;
                                    </div>
                                    <div>
                                        <span className="year">{renderHeaderYearValue()}</span>
                                        <span className="currency-amount">({renderCurrencyValue()})</span>
                                    </div>
                                </div>
                                <div className="table-list__body">
                                    {
                                        Object.keys(ratiosDict).map(key => (
                                            <div className="table-list__body-row" key={key}>
                                                <div className="table-list__body-col">
                                            <span className="description">
                                                {LabelHelper.GetLabel(labelCategory, key, key)}
                                            </span>
                                                    <span
                                                        className="cost">{isDefault ? "xxxxx" : ratiosDict[key]}</span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </CompanyScore>
    )
};
CompanyKeyFigureScore.defaultProps = {
    balance: {},
    incomeStatement: {},
    ratios: {}
};

export default CompanyKeyFigureScore;
