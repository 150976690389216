import * as React from "react";
import cn from "classnames";

import {IOption} from "./common/form/fields/SelectableInput";
import { getTopLevelDomain } from "../../react-shared/helpers/locationHelper";

const BEDomainLanguageOptions = [
    {
        view: "FR",
        value: "fr-BE",
        key: "fr"
    },
    {
        view: "NL",
        value: "nl-BE",
        key: "nl"
    }
];

const generalLanguageOptions: IOption<string, string>[] = [
    //{
    //    view: "EN",
    //    value: "en-GB",
    //    key: "uk"
    //},
    {
        view: "FR",
        value: "fr-FR",
        key: "fr"
    },
    {
        view: "NL",
        value: "nl-NL",
        key: "nl"
    }
];

const getAvailableLanguageOptions = () => {
    switch (getTopLevelDomain()) {
        case "be":
            return BEDomainLanguageOptions;
        default:
            return generalLanguageOptions;
    }
};

const bc = "r_language-select-switcher";

export class LanguageSwitcher extends React.PureComponent {
    onSelect(languageOption: IOption<string, string>) {
        let newLocation = window.location.href;
        let pathParts = window.location.pathname.split('/');

        let currentLanguage = pathParts[1];

        if (currentLanguage != null && currentLanguage.length == 2) {
            pathParts[1] = languageOption.key; // Replace language
        } else {
            pathParts[1] = languageOption.key + "/" + pathParts[1]; // Set language
        }

        window.location.replace(window.location.origin + pathParts.join('/'));
    }

    render() {
        const options = getAvailableLanguageOptions();
        return (
            <div className={bc}>
                {options.map((option: IOption, i: number) => <span
                    className={cn("laguage-wrapper", i < options.length - 1 && "separated")} key={option.key}
                    onClick={() => this.onSelect(option)}>{option.view}</span>)}
            </div>
        )
    }
}
