import * as React from "react";
import cn from "classnames";
import { LabelCategory, LabelHelper } from "../../helpers/LabelHelper";
import Card, { CardBody, CardHeader } from "../../../react-shared/components/Card";
import { Tooltip } from "../Tooltip";
import { CompanyScore, ICompanyScoreProps } from "./CompanyScore";

const bc = "company-score";

export interface ICompanyInfoExtScoreProps extends ICompanyScoreProps {
    labelCategory: LabelCategory;
    foundationDate?: Date;
    numberOfBranches?: number;
    companyRegistrationId?: string;
    companyEnterpriseId?: number;
    legalFormDescription?: string;
    activitiesText?: string;
    webSiteUrl?: string;
    employeeTeamClassification?: string;
    isDefault?: boolean;
    explanation?: string;
    linkOnClickBehavior?: ILinkOnClickBehavior;
}

export interface ILinkOnClickBehavior {
    handle(e: any, url: string): void;
}

const linkOnClickBehavior = {
    handle() {
    }
} as ILinkOnClickBehavior;

const CompanyInfoExtScore: React.FunctionComponent<ICompanyInfoExtScoreProps> = ({
                                                                                     foundationDate,
                                                                                     companyRegistrationId,
                                                                                     legalFormDescription,
                                                                                     webSiteUrl,
                                                                                     employeeTeamClassification,
                                                                                     numberOfBranches,
                                                                                     activitiesText,
                                                                                     isDefault,
                                                                                     explanation,
                                                                                     linkOnClickBehavior,
                                                                                     labelCategory,
                                                                                     ...props
                                                                                 }) => {
    const noData = isDefault ? "xxxxx" : "-";
    const formatDate = (date: Date | string) => {
        if (!date) {
            return noData;
        }

        date = new Date(date);

        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();
        return `${day < 10 ? "0" + day : day}-${month < 10 ? "0" + month : month}-${year}`;
    };
    const renderFoundationDateValue = () => {
        if (isDefault) {
            return "xxxxx";
        }
        return foundationDate ? formatDate(foundationDate) : "-";
    };
    const renderCompanyIdValue = () => {
        if (isDefault) {
            return "xxxxx";
        }
        return companyRegistrationId ? companyRegistrationId : "-";
    };
    const renderLegalFormValue = () => {
        if (isDefault) {
            return "xxxxx";
        }
        return legalFormDescription ? legalFormDescription : "-";
    };
    const renderWebsiteUrl = () => {
        if (isDefault) {
            return "xxxxx";
        }
        const onClick = e => {
            linkOnClickBehavior.handle(e, webSiteUrl);
        };
        return webSiteUrl ? <a onClick={onClick} href={webSiteUrl}>{webSiteUrl}</a> : "-";
    };
    const renderNumberOfBranchesValue = () => {
        if (isDefault) {
            return "xxxxx";
        }
        return numberOfBranches ? numberOfBranches : "-";
    };
    const renderEmployeeTeamClassificationValue = () => {
        if (isDefault) {
            return "xxxxx";
        }
        return employeeTeamClassification ? employeeTeamClassification : "-";
    };
    const renderActivitiesTextValue = () => {
        if (isDefault) {
            return "xxxxx";
        }
        return activitiesText ? activitiesText : "-";
    };

    return (
        <CompanyScore {...props} className={cn(bc, "company-info", props.className)}>
            <Card {...props}>
                <CardHeader>
                    <div className="score-header">
                        <h3>
                            {LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyInfoExtCard_HeaderText", "Bedrijfsinformatie")}
                        </h3>
                        {explanation ? <Tooltip text={explanation} className="explanation-tooltip"/> : null}
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="info-container">
                        <div className="row">
                            <div className="col-medium-6">
                                <div className="info-container__item">
                                    <span>{LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyInfoCard_DateOfRegistrationHeaderText", "Oprichtingsdatum")}</span>
                                    <span>{renderFoundationDateValue()}</span>
                                </div>
                                <div className="info-container__item">
                                    <span>{LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyInfoCard_CompanyNumberHeaderText", "Ondernemingsnummer")}</span>
                                    <span>{renderCompanyIdValue()}</span>
                                </div>
                                <div className="info-container__item">
                                    <span>{LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyInfoCard_LegalNameHeaderText", "Rechtsvorm")}</span>
                                    <span>{renderLegalFormValue()}</span>
                                </div>
                                <div className="info-container__item">
                                    <span>{LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyInfoCard_WebsiteHeaderText", "Website")}</span>
                                    {renderWebsiteUrl()}
                                </div>
                            </div>
                            <div className="col-medium-6">
                                <div className="info-container__item">
                                    <span>{LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyInfoCard_NumberOfBranchesHeaderText", "Aantal vestigingen")}</span>
                                    <span>{renderNumberOfBranchesValue()}</span>
                                </div>
                                <div className="info-container__item">
                                    <span>{LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyInfoCard_EmployeeAmountHeaderText", "Personeelsklasse omvang")}</span>
                                    <span>{renderEmployeeTeamClassificationValue()}</span>
                                </div>
                                <div className="info-container__item">
                                    <span>{LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyInfoCard_NACEHeaderText", "Activiteiten (nace code)")}</span>
                                    <span>{renderActivitiesTextValue()}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </CompanyScore>
    )
};
CompanyInfoExtScore.defaultProps = {
    linkOnClickBehavior: linkOnClickBehavior
};

export default CompanyInfoExtScore;
