import * as React from "react";
import { ProductItemCard } from "../components/ProductItemCard";
import { CompanySearchInput } from "../components/common/form/fields/CompanySearchInput";
import { LabelCategory, LabelHelper } from "../../react-shared/helpers/LabelHelper";
import { DomainToCountryMap, getCountryBasedOnDomain } from "../../react-shared/helpers/countryHelper";

const bc = "pricing-container";

enum StepNames {
    ChooseFormulaBE = "choose_formula_be",
    ChooseFormulaNL = "choose_formula_nl",
    FindCompany = "find-company",
    ChooseQuantity = "choose_quantity",
    InterpretReport = "interpret_report"
}

interface IProductsContainerProps {
    steps: StepNames[];
}

export const ProductsContainer: React.FunctionComponent<IProductsContainerProps> = ({ steps }) => {
    const [selectedReportAmount, setSelectedReportAmount] = React.useState(5);
    const country = getCountryBasedOnDomain();
    const labels = {
        StepText: LabelHelper.GetLabel(LabelCategory.HomeReportCards, "StepText", "Stap", "Étape", "Stap", "Step"),
        StepChooseYourFormula_Name: LabelHelper.GetLabel(LabelCategory.HomeReportCards, "StepChooseYourFormula_Name", "Kies uw formule", "Choisissez votre formule", "Kies uw formule", "Choose your formula"),
        StepFindYourFirstCompany_Name: LabelHelper.GetLabel(LabelCategory.HomeReportCards, "StepFindYourFirstCompany_Name", "Zoek uw eerste bedrijf", "Cherchez une première entreprise"),
        StepInterpretTheReport_Name: LabelHelper.GetLabel(LabelCategory.HomeReportCards, "StepInterpretTheReport_Name", "Interpreteer het rapport", "Interprétez le rapport"),
        StepChooseYourQuantity_Name: LabelHelper.GetLabel(LabelCategory.HomeReportCards, "StepChooseYourQuantity_Name", "Kies je hoeveelheid", "Choisissez votre quantité", "Kies je hoeveelheid", "Choose your quantity"),
        GraydonPrices_NL_Text: LabelHelper.GetLabel(LabelCategory.HomeReportCards, "GraydonPrices_NL_Text", "GraydonGo prijzen: bedrijfsrapporten. Kies tijdens het afrekenen de gewenste hoeveelheid rapporten", "", "", "")
    };
    const stepComponents = [
        {
            name: StepNames.ChooseFormulaBE,
            render: (stepNumber: number) => (
                <div className="step-section">
                    <div className="step-section__header">
                        <div className="row">
                            <div className="col-medium-12 step-section__header-row">
                            <span
                                className="step-number">{labels.StepText} {stepNumber}</span>
                                <span className="step-name">{labels.StepChooseYourFormula_Name}</span>
                            </div>
                        </div>
                    </div>
                    <div className="step-section__description">
                        <div className="formula-container">
                            <div className="formula-box">
                                <div className="formula-box__title">{LabelHelper.GetLabel(LabelCategory.HomeReportCards, "Formula_FinancialHealthIndicator_Title", "Indicatie financiële gezondheid", "Indication gratuite de la santé financière")}</div>
                                <div className="formula-box__description">{LabelHelper.GetLabel(LabelCategory.HomeReportCards, "Formula_FinancialHealthIndicator_Description", "Gratis", "Gratuite")}</div>
                            </div>

                            <div className="formula-box">
                                <div className="formula-box__title">{LabelHelper.GetLabel(LabelCategory.HomeReportCards, "Formula_SummaryReport_Title", "Samenvatting rapport", "Résumé du rapport")}</div>
                                <div className="formula-box__description">{LabelHelper.GetLabel(LabelCategory.HomeReportCards, "Formula_SummaryReport_Description", "Apart verkrijgbaar voor €15", "Disponible séparément pour €15")}</div>
                            </div>

                            <div className="formula-box">
                                <div className="formula-box__title">{LabelHelper.GetLabel(LabelCategory.HomeReportCards, "Formula_FullReport_Title", "Uitgebreid rapport", "Rapport complet")}</div>
                                <div className="formula-box__description">{LabelHelper.GetLabel(LabelCategory.HomeReportCards, "Formula_FullReport_Description", "Apart en per pakket verkrijgbaar vanaf €25", "Disponible séparément et par colis à partir de 25€")}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        },
        {
            name: StepNames.ChooseFormulaNL,
            render: (stepNumber: number) => (
                <div className="step-section">
                    <div className="step-section__header">
                        <div className="row">
                            <div className="col-medium-12 step-section__header-row">
                                <span className="step-number">{labels.StepText} {stepNumber}</span>
                                <span className="step-name">{labels.StepChooseYourFormula_Name}</span>
                            </div>
                        </div>
                    </div>
                    <div className="step-section__products">
                        <ProductItemCard 
                            price={45} 
                            reportAmount={1} 
                            isSelected={selectedReportAmount === 1}
                            onSelect={() => setSelectedReportAmount(1)} 
                            asRadio={country === DomainToCountryMap.be}
                            tooltipText={country === DomainToCountryMap.nl ? labels.GraydonPrices_NL_Text : null}
                        />
                        <ProductItemCard 
                            price={200} 
                            reportAmount={5} 
                            isSelected={selectedReportAmount === 5}
                            onSelect={() => setSelectedReportAmount(5)} 
                            favorite 
                            asRadio={country === DomainToCountryMap.be}
                            tooltipText={country === DomainToCountryMap.nl ? labels.GraydonPrices_NL_Text : null}
                        />
                        <ProductItemCard 
                            price={350} 
                            reportAmount={10} 
                            isSelected={selectedReportAmount === 10}
                            onSelect={() => setSelectedReportAmount(10)} 
                            asRadio={country === DomainToCountryMap.be}
                            tooltipText={country === DomainToCountryMap.nl ? labels.GraydonPrices_NL_Text : null}
                        />
                    </div>
                </div>
            )
        },
        {
            name: StepNames.FindCompany,
            render: (stepNumber: number) => (
                <div className="step-section">
                    <div className="step-section__header">
                        <div className="row">
                            <div className="col-medium-12 step-section__header-row">
                                <span className="step-number">{labels.StepText} {stepNumber}</span>
                                <span className="step-name">{labels.StepFindYourFirstCompany_Name}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-medium-12">
                            <div className="step-section__description">
                                <div dangerouslySetInnerHTML={{
                                    __html: LabelHelper.GetLabel(LabelCategory.HomeReportCards,
                                        "step-2-description-text",
                                        `<p>Geef in de zoekbalk de zoektermen in om een bedrijf te vinden.</p>`,
                                        `<p>Entrez les termes de recherche dans la barre de recherche pour trouver une entreprise.</p>`
                                    )
                                }}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-medium-12">
                            <div className="step-section__company-search">
                                <div className="search-company-small">
                                    <div className="relative">
                                        <CompanySearchInput
                                            size="small"
                                            action=""
                                            placeholder={LabelHelper.GetLabel(LabelCategory.HomeReportCards, "search-report-placeholder", "Voer bedrijfsnaam, ondernemings- of btw-nr in")}
                                            forAmount={selectedReportAmount}
                                            withHint
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        },
        {
            name: StepNames.ChooseQuantity,
            render: (stepNumber: number) => (
                <div className="step-section">
                    <div className="step-section__header">
                        <div className="row">
                            <div className="col-medium-12 step-section__header-row">
                                <span className="step-number">{labels.StepText} {stepNumber}</span>
                                <span className="step-name">{labels.StepChooseYourQuantity_Name}</span>
                            </div>
                        </div>
                    </div>
                    <div className="step-section__products">
                        <ProductItemCard price={45} reportAmount={1}/>
                        <ProductItemCard price={200} reportAmount={5} favorite/>
                        <ProductItemCard price={350} reportAmount={10}/>
                        <ProductItemCard price={750} reportAmount={25}/>
                        <ProductItemCard price={1250} reportAmount={50}/>
                    </div>
                </div>
            )
        },
        {
            name: StepNames.InterpretReport,
            render: (stepNumber: number) => (
                <div className="step-section">
                    <div className="step-section__header">
                        <div className="row">
                            <div className="col-medium-12 step-section__header-row">
                            <span
                                className="step-number">{labels.StepText} {stepNumber}</span>
                                <span
                                    className="step-name">{labels.StepInterpretTheReport_Name}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-medium-12">
                            <div className="step-section__description">
                                <div dangerouslySetInnerHTML={{
                                    __html: LabelHelper.GetLabel(
                                        LabelCategory.HomeReportCards,
                                        "step-3-description-text",
                                        `<p>Begin direct met het interpreteren van het rapport en maak zo gefundeerde beslissingen. Bekijk <a style="color:#eb6e08" href="/nl/example-company-report">in dit voorbeeldrapport</a> de tooltips en kom zo meer te weten over de scores.</p>`,
                                        `<p>Commencez immédiatement à interpréter le rapport pour prendre des décisions en connaissance de cause. Consultez les infobulles dans <a style="color:#eb6e08" href="/fr/example-company-report">cet exemple de rapport</a> pour en savoir plus sur les données et les scores.</p>`,
                                        `<p>Begin direct met het interpreteren van het rapport en maak zo gefundeerde beslissingen. Bekijk <a style="color:#eb6e08" href="/nl/example-company-report">in dit voorbeeldrapport</a> de tooltips en kom zo meer te weten over de scores.</p>`,
                                    )
                                }}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    ];

    return (
        <div className={bc}>
            {steps.map((step, i) => stepComponents.find(it => it.name === step)?.render(i + 1))}
        </div>
    )
};

ProductsContainer.defaultProps = {
    steps: []
};
