import * as React from "react";
import { ICompanySearchIdentification, ICompanySearchProfile } from "./CompanySearchInput";
import {
    getHostnameWithProtocolAndLanguage,
    getTopLevelDomain
} from "../../../../../react-shared/helpers/locationHelper";
import { LabelCategory, LabelHelper } from "../../../../../react-shared/helpers/LabelHelper";
import cn from "classnames";

const queryString = require("query-string");


interface IProps {
    forAmount?: number;
    highLightedWord: string;
    companyProfile: ICompanySearchProfile;
    companyIdentification: ICompanySearchIdentification;
}

interface IHighlightProps {
    text: string;
    highLightedWord: string;
    colorClass: string;
}

interface IState {
}

class HighlightText extends React.PureComponent<IHighlightProps> {
    findWord(substring: string, text: string) {
        if (!substring || !text) {
            return null;
        }

        const regExp = new RegExp(`${substring}`, "mi");
        const match = text.match(regExp);
        return match ? match[0] : null;
    }

    render() {
        const hightlitedWord = this.findWord(this.props.highLightedWord, this.props.text);
        if (!hightlitedWord) {
            return <span>{this.props.text}</span>
        }

        const splittedTextByFoundWord = this.props.text.split(hightlitedWord);

        return (
            <span
                dangerouslySetInnerHTML={{ __html: splittedTextByFoundWord.join(`<span class="${this.props.colorClass}">${hightlitedWord}</span>`) }}/>
        )
    }
}

class CompanySearchResultItem extends React.PureComponent<IProps, IState> {
    static defaultProps = {
        forAmount: 1
    };

    getQueryParams = () => {
        const params: Record<string, string> = {};

        if (this.props.forAmount === 5) {
            params.product = "FiveCredit";
        }
        if (this.props.forAmount === 10) {
            params.product = "TenCredit";
        }
        if (this.props.forAmount === 25) {
            params.product = "TwentyFiveCredit";
        }
        if (this.props.forAmount === 50) {
            params.product = "FiftyCredit";
        }

        const str = queryString.stringify(params);

        return str != "" ? `?${str}` : "";
    }

    getBuyFullReportLink() {
        return `${getHostnameWithProtocolAndLanguage()}/graydondocument/${this.props.companyIdentification.graydonEnterpriseId}${this.getQueryParams()}`;
    }

    getBuyShortReportLink() {
        return `${getHostnameWithProtocolAndLanguage()}/graydondocument/${this.props.companyIdentification.graydonEnterpriseId}?product=ShortReport`;
    }

    getActions() {
        const domain = getTopLevelDomain();

        if (domain !== "nl") {
            const buyFullReportLink = this.getBuyFullReportLink();
            let profileUrl = `${getHostnameWithProtocolAndLanguage()}/${this.props.companyIdentification.countryCode.toLowerCase()}-${(this.props.companyIdentification.countryCode != "GB" ? this.props.companyIdentification.registrationId : this.props.companyIdentification.graydonEnterpriseId)}`;
           
            const freeScoreLink = `${profileUrl}#financialinfo`;
            const buyShortReportLink = this.getBuyShortReportLink();

            return (
                <>
                    <a href={freeScoreLink} className="result-item-button oc-icon-company icon--m">
                        <span>{LabelHelper.GetLabel(LabelCategory.CompanySearch, "FreeIndicationButton", "Gratis indicatie", "Indication gratuite", "Gratis indicatie", "Free indication")}</span>
                    </a>
                    <a href={buyShortReportLink} className="result-item-button oc-icon-report icon--m">
                        <span>{LabelHelper.GetLabel(LabelCategory.CompanySearch, "BuyShortReportButton", "Koop samenvatting", "J’achète le résumé", "Koop samenvatting", "Buy summary")}</span>
                    </a>
                    <a href={buyFullReportLink} className="result-item-button oc-icon-report icon--m">
                        <span>{LabelHelper.GetLabel(LabelCategory.CompanySearch, "BuyFullReportButton", "Koop uitgebreid rapport", "J’achète le rapport complet", "Koop uitgebreid rapport", "Buy comprehensive report")}</span>
                    </a>
                </>
            )
        } else {
            const buyLink = this.getBuyFullReportLink();
            let profileUrl = `${getHostnameWithProtocolAndLanguage()}/${this.props.companyIdentification.countryCode.toLowerCase()}-${(this.props.companyIdentification.countryCode != "GB" ? this.props.companyIdentification.registrationId : this.props.companyIdentification.graydonEnterpriseId)}`;
   
            return (
                <>
                    <a href={profileUrl} className="result-item-button oc-icon-company icon--m">
                        <span>{LabelHelper.GetLabel(LabelCategory.CompanySearch, "MoreInfoButton", "Meer info")}</span></a>
                    <a href={buyLink} className="result-item-button oc-icon-report icon--m">
                        <span>{LabelHelper.GetLabel(LabelCategory.CompanySearch, "BuyCompenyReport", "Koop")}</span>
                    </a>
                </>
            )
        }
    }

    render() {
        const domain = getTopLevelDomain();
        let profileUrl = `${getHostnameWithProtocolAndLanguage()}/${this.props.companyIdentification.countryCode.toLowerCase()}-${(this.props.companyIdentification.countryCode != "GB" ? this.props.companyIdentification.registrationId : this.props.companyIdentification.graydonEnterpriseId)}`;

        return (
            <div className="container">
                <div className="company-search-item">
                    <p className="company-name">
                        {
                            <a href={profileUrl}>
                                <HighlightText
                                    text={this.props.companyProfile.companyName}
                                    highLightedWord={this.props.highLightedWord}
                                    colorClass="search-highlight"
                                />
                            </a>
                        }
                    </p>
                    <div>
                        <span className="company-identification">
                            {LabelHelper.GetLabel(LabelCategory.CompanySearch, `EnterpriseRegistrationNumber-${this.props.companyIdentification.countryCode.toLowerCase()}`, "KVK-nummer", "Numéro d'entreprise", "Ondernemingsnummer", "Company number")}: {this.props.companyIdentification.registrationId}
                            {" | "}
                            {LabelHelper.GetLabel(LabelCategory.CompanySearch, "GraydonNumber", "Graydon nummer")}: {this.props.companyIdentification.graydonEnterpriseId}
                        </span>
                    </div>
                    <div className="company-sub-info">
                        <span
                            className={`company-active ` + (this.props.companyProfile.isActive && "active")}>{this.props.companyProfile.isActive ? LabelHelper.GetLabel(LabelCategory.CompanySearch, "Company_Search_Active", "ACTIEF")
                            : LabelHelper.GetLabel(LabelCategory.CompanySearch, "Company_Search_InActive", "NIET ACTIEF")}</span>
                        <span
                            className={`company-type ` + (this.props.companyIdentification.isBranchOffice && "branchoffice")}>{this.props.companyIdentification.isBranchOffice ?
                            LabelHelper.GetLabel(LabelCategory.CompanySearch, "Company_Search_BranchOffice", "V") :
                            LabelHelper.GetLabel(LabelCategory.CompanySearch, "Company_Search_HQ", "HV")}</span>
                        <div className="company-city icon--s oc-icon-location">{this.props.companyProfile.city}</div>
                    </div>
                </div>
                <div className={cn("buttons-right-under", domain === "nl" && "nl")}>
                    {this.getActions()}
                </div>
            </div>
        )
    }
}

export default CompanySearchResultItem;
