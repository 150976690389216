import * as React from "react";
import cn from "classnames";
import { LabelCategory, LabelHelper } from "../../helpers/LabelHelper";

const bc = "company-grade-chart";

export type GradeChartMode = "bar" | "half-pie" | "half-pie-group";

export enum CompanyGrade {
    AAA = "aaa",
    AA = "aa",
    A = "a",
    BBB = "bbb",
    BB = "bb",
    B = "b",
    CCC = "ccc",
    CC = "cc",
    C = "c",
    D = "d",
    NR = "nr"
}

enum GradeClass {
    DarkGreen = "DarkGreen",
    Green = "Green",
    Yellow = "Yellow",
    Orange = "Orange",
    Red = "Red"
}

const CompanySlice: React.FunctionComponent<{ grade: CompanyGrade, isActive: boolean, hideLegend: boolean; }> = ({
                                                                                                                     grade,
                                                                                                                     isActive,
                                                                                                                     hideLegend
                                                                                                                 }) => (
    <div className={cn(bc, "chart-slice", `chart-slice--${grade.toLowerCase()}`, isActive && "active")}>
        {hideLegend ? null : <span className={`${bc}__legend-text`}><span>{grade.toUpperCase()}</span></span>}
    </div>
)

interface IProps {
    grade: CompanyGrade;
    isDefault: boolean;
    mode?: GradeChartMode;
    hideLegend?: boolean;
    labelCategory: LabelCategory;
    hideScore?: boolean;
    multiScoreValue?: string;
}

const CompanyGradeChart: React.FunctionComponent<IProps> = ({ grade, isDefault, mode, hideLegend, labelCategory, hideScore, multiScoreValue }) => {
    const theGrade = (isDefault ? CompanyGrade.CCC : CompanyGrade[grade.toUpperCase()]);
    const labels = {
        CompanyProfilePage_CompanyGrade_Description_DarkGreen: LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyGrade_Description_DarkGreen", "Laag tot zeer laag risico", "Risque faible à très faible", "Laag tot zeer laag risico", "Low to very low risk"  ),
        CompanyProfilePage_CompanyGrade_Description_Green: LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyGrade_Description_Green", "Matig tot laag risico", "Risque modéré à faible", "Matig tot laag risico", "Moderate to low risk"),
        CompanyProfilePage_CompanyGrade_Description_Yellow: LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyGrade_Description_Yellow", "Matig risico", "Risque modéré", "Matig risico", "Moderate risk"),
        CompanyProfilePage_CompanyGrade_Description_Orange: LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyGrade_Description_Orange", "Hoog tot matig risico", "Risque élevé à modéré", "Hoog tot matig risico", "High to moderate risk"),
        CompanyProfilePage_CompanyGrade_Description_Red: LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyGrade_Description_Red", "Hoog tot zeer hoog risico", "Risque élevé à très élevé", "Hoog tot zeer hoog risico", "High to very high risk"),
        CompanyProfilePage_CompanyGrade_Description_Unknown: LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyGrade_Description_Unknown", "Er kan geen rating vastgesteld worden", "L'entreprise n'est pas notée", "Er kan geen rating vastgesteld worden", "No rating can be determined"),
        CompanyProfilePage_CompanyMultiScore_Title: LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyMultiScore_Title", "multiscore", "multiscore")
    };
    const getModeClass = () => {
        switch (mode) {
            case "bar":
            default:
                return `${bc}--bar`;

            case "half-pie":
                return `${bc}--half-pie`;

            case "half-pie-group":
                return `${bc}--half-pie-group`;
        }
    }

    const gradeToClass = (grade: CompanyGrade) => {
        switch (grade) {
            default:
                return GradeClass.Red;

            case CompanyGrade.AA:
            case CompanyGrade.AAA:
                return GradeClass.DarkGreen;

            case CompanyGrade.A:
                return GradeClass.Green;

            case CompanyGrade.BB:
            case CompanyGrade.BBB:
            case CompanyGrade.NR:
                return GradeClass.Yellow;

            case CompanyGrade.B:
            case CompanyGrade.CC:
            case CompanyGrade.CCC:
                return GradeClass.Orange;

            case CompanyGrade.C:
            case CompanyGrade.D:
                return GradeClass.Red;
        }
    }
    const gradeClass = gradeToClass(theGrade);

    const getGradeExplanation = (mode: GradeChartMode) => {
        if (mode === "half-pie-group") {
            if (theGrade == CompanyGrade.NR) {
                return labels.CompanyProfilePage_CompanyGrade_Description_Unknown;
            }

            switch (gradeClass) {

                case GradeClass.DarkGreen:
                    return labels.CompanyProfilePage_CompanyGrade_Description_DarkGreen;
                case GradeClass.Green:
                    return labels.CompanyProfilePage_CompanyGrade_Description_Green;
                case GradeClass.Yellow:
                    return labels.CompanyProfilePage_CompanyGrade_Description_Yellow;
                case GradeClass.Orange:
                    return labels.CompanyProfilePage_CompanyGrade_Description_Orange;
                case GradeClass.Red:
                    return labels.CompanyProfilePage_CompanyGrade_Description_Red;
            }
        }

        switch (theGrade) {
            case CompanyGrade.AAA:
                return LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyGrade_Description_AAA", "Kans op wanbetaling is zeer laag", "Le risque financier est considéré comme très faible");
            case CompanyGrade.AA:
                return LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyGrade_Description_AA", "Kans op wanbetaling is laag", "Le risque financier est considéré comme faible");
            case CompanyGrade.A:
                return LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyGrade_Description_A", "Kans op wanbetaling is laag", "Le risque de défaut de paiement est faible", "Kans op wanbetaling is laag", "Chance of non-payment is low");
            case CompanyGrade.BBB:
                return LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyGrade_Description_BBB", "Kans op wanbetaling is gemiddeld", "Le risque financier est considéré comme moyen");
            case CompanyGrade.BB:
                return LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyGrade_Description_BB", "Kans op wanbetaling is gemiddeld", "Le risque financier est considéré comme moyen");
            case CompanyGrade.B:
                return LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyGrade_Description_B", "Kans op wanbetaling is gemiddeld", "Le risque de défaut de paiement est moyen", "Kans op wanbetaling is gemiddeld", "Chance of non-payment is average");
            case CompanyGrade.CCC:
                return LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyGrade_Description_CCC", "Kans op wanbetaling is hoger dan het gemiddelde", "Le risque financier est considéré comme supérieur à la moyenne");
            case CompanyGrade.CC:
                return LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyGrade_Description_CC", "Kans op wanbetaling is hoger dan het gemiddelde", "Le risque financier est considéré comme supérieur à la moyenne");
            case CompanyGrade.C:
                return LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyGrade_Description_C", "Kans op wanbetaling is hoog", "Le risque de défaut de paiement est élevé", "Kans op wanbetaling is hoog", "Chance of non-payment is high");
            case CompanyGrade.D:
                return LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyGrade_Description_D", "De onderneming is insolvent", "La société est insolvable", "De onderneming is insolvent", "The company is insolvent");
            case CompanyGrade.NR:
                return LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CompanyGrade_Description_NR", "Er kan geen rating vastgesteld worden", "L'entreprise n'est pas notée", "Er kan geen rating vastgesteld worden", "No rating can be determined");
        }
    }

    const renderSlices = () => [
        CompanyGrade.AAA,
        CompanyGrade.AA,
        CompanyGrade.A,
        CompanyGrade.BBB,
        CompanyGrade.BB,
        CompanyGrade.B,
        CompanyGrade.CCC,
        CompanyGrade.CC,
        CompanyGrade.C,
        CompanyGrade.D
    ].map(grade => <CompanySlice key={grade} hideLegend={hideLegend} grade={grade} isActive={theGrade === grade}/>);

    const renderBarChart = () => (
        <div className={`${bc}__columns`}>
            {renderSlices()}
        </div>
    );

    const renderHalfPieChart = () => (
        <div className={`${bc}__pie-slices`}>
            {renderSlices()}
            <div className="arrow">
                <span className={`arrow--${gradeClass.toLowerCase()}`}/>
            </div>
        </div>
    );

    const renderHalfPieGroupChart = () => {
        return (
            <div className={cn(`${bc}__pie-group`, `${bc}__pie-group--${gradeClass.toLowerCase()}-grade`)}>
                <div className="triangle" />
                <img src="/img/grade-chart/delphi-meter.svg" alt="Company grade group chart" />
            </div>
        )
    };

    const renderChart = (mode: GradeChartMode) => {
        if (mode === "bar") {
            return renderBarChart();
        }
        if (mode === "half-pie") {
            return renderHalfPieChart();
        }
        if (mode === "half-pie-group") {
            return renderHalfPieGroupChart();
        }
    };

    return (
        <div className={cn(bc, getModeClass())}>
            {hideScore ? null : <span className={`${bc}__grade-title`}>{isDefault ? "X" : theGrade.toUpperCase() }</span>}
            {multiScoreValue ? <span className={`${bc}__multiscore`}>({labels.CompanyProfilePage_CompanyMultiScore_Title}: {multiScoreValue})</span> : null}
            {renderChart(mode)}
            <span className={`${bc}__grade-description`}>{isDefault ? "xxxxxxxxxxx" : (getGradeExplanation(mode) || "-")}</span>
        </div>
    )
};

CompanyGradeChart.defaultProps = {
    mode: "bar"
}

export default CompanyGradeChart;
