import * as React from "react";
import cn from "classnames";

interface IProps {
    label: string;
    onClick?: () => void;
    wide?: boolean;
    secondary?: boolean;
    primary?: boolean;
    type: "submit" | "button";
    disabled?: boolean;
    blury?: boolean;
    rounded?: boolean;
    isLoading?: boolean;
    fullWidth?: boolean;
}

const bc = "button";
const secondaryClass = "btn--secondary";
const primaryClass = "btn--primary";

const Button: React.FunctionComponent<IProps> = ({
                                                     label, onClick = () => {
    },
                                                     wide,
                                                     secondary,
                                                     type,
                                                     primary,
                                                     blury,
                                                     rounded,
                                                     disabled,
                                                     isLoading,
                                                     fullWidth
                                                 }) => (
    <div className={`${bc} form__buttons`}>
        <button
            className={cn(secondary && secondaryClass, fullWidth && "full-width", primary && primaryClass, wide && "wide", blury && "blury", rounded && "rounded", isLoading && "loading")}
            onClick={onClick}
            type={type}
            disabled={disabled}
        >
            {isLoading && <div className="spinner"></div>}
            <span style={{ visibility: isLoading ? "hidden" : "visible" }}>{label}</span>
        </button>
    </div>
);

export default Button;
