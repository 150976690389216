import * as React from "react";
import cn from "classnames";
// @ts-ignore
import { Field } from 'formik';
import ErrorTooltip from "../ErrorTooltip";

const bc = "r_checkbox";

interface IProps {
    label?: string;
    name: string;
    value?: any;
    disabled?: boolean;
    errors?: Record<string, string>;
    disableHtmlFor?: boolean;
}

const Checkbox: React.FunctionComponent<IProps> = (props: IProps) => {
    const errors = props.errors || {};
    return (
        <Field name={props.name} type="checkbox">
            {({ field, form }) => (
                <div className={cn(bc, "form__field--check-new")}>
                    <input
                        id={field.name}
                        {...field}
                        checked={field.value}
                        type="checkbox"
                        disabled={props.disabled}
                        onChange={() => form.setFieldValue(props.name, true)}
                    />
                    <div className="check-button-container" onClick={() => form.setFieldValue(props.name, false)}>
                        {field.value && <div className="check-button-on icon--s oc-icon-check" style={{ transform: "unset" }}></div>}
                        {
                            typeof props.label === "string"
                                ? <label htmlFor={props.disableHtmlFor ? null : props.name} dangerouslySetInnerHTML={{ __html: props.label }} />
                                : <label htmlFor={props.disableHtmlFor ? null : props.name}>{props.label}</label>
                        }
                    </div>
                    {errors[field.name] && form.touched[field.name] && <ErrorTooltip text={errors[field.name]} />}
                </div>
                )}
        </Field>
    )
};

export default Checkbox;
