import * as React from 'react';
import * as $ from 'jquery';
import { LabelCategory, LabelHelper } from "../../react-shared/helpers/LabelHelper";


interface FollowCompanyState {
    Following: boolean;
    DialogVisible: boolean;
    Loading: boolean;
    FollowUsed: number;
}

export class FollowCompanyButton extends React.Component<any, FollowCompanyState> {
    static defaultProps = {
        IsDialogVisible: false
    }

    constructor(props) {
        super(props);
        this.state = { Following: false, DialogVisible: false, Loading: false, FollowUsed: 0 };
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            DialogVisible: this.props.IsDialogVisible
        });
    }

    componentWillUnmount() {
    }

    buttonClick() {
        if (this.state.Loading) {
            return;
        }

        this.setState(Object.assign({}, this.state, { DialogVisible: true }));
    }

    closeDialog() {
        this.setState(Object.assign({}, this.state, { DialogVisible: false }));
    }

    render() {
        let img = this.state.Following ? "heart-full.png" : "heart-empty.png";

        return <div>
            <img id="bp-hartje-btn" className={"follow-company-button "} src={"/img/icons/" + img} onClick={() => this.buttonClick()} title={this.state.Following ?
                                                                                                                    LabelHelper.GetLabel(LabelCategory.FollowCompany, "MouseOverTooltipStopFollowing", "Dit bedrijf niet meer volgen") :
                                                                                                                         LabelHelper.GetLabel(LabelCategory.FollowCompany, "MouseOverTooltipStartFollowing", "Ontvang email alerts over wijzigingen binnen dit bedrijf") } />
            {this.state.DialogVisible ?
                <div className="follow-company-dialog">
                    <i className="oc-icon-multiply close-follow-window-button" onClick={() => this.closeDialog()}></i>
                    {
                        <div dangerouslySetInnerHTML={{
                        __html: LabelHelper.GetLabel(LabelCategory.FollowCompany, "FollowButtonAfterRedirectText", "Wist je dat je als klant bedrijven kunt volgen? <a href='/'>Bekijk de mogelijkheden</a> om alerts te ontvangen.")
                        }} />                             
                    }

                </div> : null
            }
        </div>;
    }
}
