import * as React from "react";
import cn from "classnames";
import 'react-popper-tooltip/dist/styles.css';
import { LabelCategory, LabelHelper } from "../../../../../react-shared/helpers/LabelHelper";
import { Tooltip } from "../../../../../react-shared/components/Tooltip";

interface IOption {
    value: any;
    text: string;
}

interface IProps {
    value: string;
    className?: string;
    name: string;
    onChange: (value: Record<any, any>) => void;
    renderOption: (option: any) => any;
    renderSelectedOption: (selectedOption: any) => any;
    withHint?: boolean;
}

interface IState {
    isOpen: boolean,
    value: any;
}

class CompanySearchFlagInput extends React.PureComponent<IProps, IState> {
    state = {
        value: this.props.value,
        isOpen: false
    } as IState;

    onChange = (e, selectedItem: IOption) => {
        e.stopPropagation();

        this.setState({
            ...this.state,
            value: selectedItem.value,
            isOpen: false
        });
        this.props.onChange({
            target: {
                value: selectedItem.value
            }
        });
    };

    onClickOutside(e) {
        if (!e.target.closest(".flag-input-wrapper")) {
            this.setState({
                ...this.state,
                isOpen: false
            });
        }
    }

    componentDidMount() {
        document.addEventListener("click", this.onClickOutside.bind(this));
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.onClickOutside.bind(this));
    }

    toggleOpen = () => {
        this.setState(state => ({
            ...this.state,
            isOpen: !state.isOpen
        }));
    }

    getSelectedValue(value: any) {
        return this.allFlags.find(it => it.value === value);
    }

    get availableFlags() {
        return this.allFlags.filter(it => it.value !== this.state.value);
    }

    get allFlags() {
        return [
            {
                value: "nl",
                text: "",
                src: "/img/flags/nl_flag.png",
                alt: "nl"
            },
            {
                value: "be",
                text: "",
                src: "/img/flags/be_flag.png",
                alt: "be"
            },
            {
                value: "gb",
                text: "",
                src: "/img/flags/gb_flag.png",
                alt: "gb"
            }
        ];
    }

    renderHint() {
        const text = LabelHelper.GetLabel(LabelCategory.CompanySearch, "SearchInputHintText", "Select country");
        return (
            <Tooltip text={text}/>
        )
    }

    render() {
        return (
            <>
                <input type="hidden" name={this.props.name} value={this.state.value}/>
                <div className="flag-input-wrapper">
                    <div className="form__flag-input form__field search-bar__input--large" onClick={this.toggleOpen}>
                        <div className={cn("flag-wrapper", this.props.withHint && "flag-wrapper--with-hint")}>
                            <div className="flag-wrapper__flag-icon">
                                {this.props.renderSelectedOption(this.getSelectedValue(this.state.value))}
                                <span>{this.getSelectedValue(this.state.value).value.toUpperCase()}</span>
                            </div>
                            {this.props.withHint ? this.renderHint() : null}
                        </div>
                        {this.state.isOpen && (
                            <div className="flag-list-container">
                                <ul>
                                    {this.availableFlags.map(it => <li key={it.value}
                                                                       onClick={(e) => this.onChange(e, it)}>{this.props.renderOption(it)}</li>)}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </>
        )
    }
}

export default CompanySearchFlagInput;
