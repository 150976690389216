import * as React from "react";
import { ProductName } from "../../common/enums/product";
import makeApiCall from "../../Helpers/makeApiCall";
// @ts-ignore
import InputField, { Input } from "../common/form/fields/Input";
// @ts-ignore
import cn from "classnames";
import { getCurrentLanguagePath } from "../../../react-shared/helpers/locationHelper";
import { LabelCategory, LabelHelper } from "../../../react-shared/helpers/LabelHelper";

const bc = "r_total-sum";

export const getProductPriceExVat = (productPricesExVat: number[], productSelection: ProductName) => {
    let priceExVat = productPricesExVat[0];
    // Find clear price of product
    if (productSelection.toString() === ProductName.FiveCredit.toString()) {
        priceExVat = productPricesExVat[1];
    }
    else if (productSelection.toString() === ProductName.TenCredit.toString()) {
        priceExVat = productPricesExVat[2];
    } else if (productSelection.toString() === ProductName.TwentyFiveCredit.toString()) {
        priceExVat = productPricesExVat[3];
    } else if (productSelection.toString() === ProductName.FiftyCredit.toString()) {
        priceExVat = productPricesExVat[4];
    } else if (productSelection.toString() === ProductName.ShortReport.toString()) {
        priceExVat = productPricesExVat[5];
    }

    return priceExVat;
};

export const getProductPricePerReport = (productPricesExVat: number, productSelection: ProductName) => {
    if (productSelection.toString() === ProductName.FiveCredit.toString()) {
        return Number((productPricesExVat / 5).toFixed(2));
    }
    if (productSelection.toString() === ProductName.TenCredit.toString()) {
        return Number((productPricesExVat / 10).toFixed(2));
    }
    if (productSelection.toString() === ProductName.TwentyFiveCredit.toString()) {
        return Number((productPricesExVat / 25).toFixed(2));
    }
    if (productSelection.toString() === ProductName.FiftyCredit.toString()) {
        return Number((productPricesExVat / 50).toFixed(2));
    }
    return productPricesExVat;
}

const updatePrices = (
    btwPercetage: number,
    productSelection: ProductName,
    productPricesExVat: number[],
    reportCount: number,
    couponCodeProperties: { percentage: number, price: number }
) => {
    let couponDiscount = 0;
    const priceExVat = getProductPriceExVat(productPricesExVat, productSelection);
    reportCount = reportCount || 1;

    let totalPriceExVat = productSelection.toString() !== ProductName.NoProduct.toString() ? priceExVat : priceExVat * reportCount;

    // Find discount
    if (couponCodeProperties.percentage) {
        couponDiscount = (totalPriceExVat / 100) * couponCodeProperties.percentage;
    }
    if (couponCodeProperties.price) {
        couponDiscount = couponCodeProperties.price;
    }
    if (couponDiscount > totalPriceExVat) {
        couponDiscount = totalPriceExVat;
    }

    totalPriceExVat -= couponDiscount;
    const totalTaxSum = +((totalPriceExVat / 100) * btwPercetage).toFixed(2);

    return {
        btwPercetage,
        totalTaxSum,
        discountPersentage: couponCodeProperties.percentage,
        discountAmount: couponDiscount,
        totalSum: totalPriceExVat + totalTaxSum,
        totalSumExludeTax: totalPriceExVat
    }
};

interface IProps {
    reportCount: number;
    productSelection: ProductName;
    btwPercetage: number;
    productPricesExVat: number[];
    onCouponCodeValid?: (couponCode: string, couponCodeProperties) => void;
    onCouponCodeInvalid?: () => void;
    initialCouponCode?: string;
    initialCouponCodeProperties?: { percentage: number, price: number };
    withCouponCode?: boolean;
    className?: string;
};

export const TotalSum: React.FunctionComponent<IProps> = ({
    reportCount = 1,
    productSelection = ProductName.NoProduct,
    btwPercetage,
    productPricesExVat,
    onCouponCodeValid = (couponCode: string, couponCodeProperties) => { },
    onCouponCodeInvalid = () => { },
    initialCouponCode = "",
    initialCouponCodeProperties = { percentage: 0, price: 0 },
    withCouponCode
}) => {
    const [couponCodeProperties, setCouponProperties] = React.useState(initialCouponCodeProperties);
    const [couponCode, setCouponCode] = React.useState(initialCouponCode);
    const [isCouponCodeValid, setIsCouponCodeValid] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const { totalTaxSum, totalSum, discountAmount, discountPersentage, totalSumExludeTax } = updatePrices(btwPercetage, productSelection, productPricesExVat, reportCount, couponCodeProperties);
    
    React.useEffect(() => {
        if (couponCode) {
            checkCouponCodeAsync();
        }
    }, []);
    
    const checkCouponCodeAsync = () => {
        setIsLoading(true);
        makeApiCall({
            url: "/" + getCurrentLanguagePath() + "/checkout/oneoff/checkCoupon/" + couponCode
        }).then(response => {
            if (response.success) {
                onCouponCodeValid(couponCode, { ...response.properties });
                setCouponProperties(response.properties);
                return;
            }
            onCouponCodeInvalid();
            setIsCouponCodeValid(false);
            setCouponProperties({ percentage: 0, price: 0 });
        }).finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <div className="product-choice-container">
            <div className="product-total">
                {!!discountPersentage && <div className="product-coupon-discount product-total-item">
                    <span>{LabelHelper.GetLabel(LabelCategory.Checkout, "DiscountPersentageLable", "Kortingscode")}</span>
                    <span className="product-coupon-discount-sum">{discountPersentage}%</span>
                </div>}
                {!!discountAmount && <div className="product-discount product-total-item">
                    <span>{LabelHelper.GetLabel(LabelCategory.Checkout, "YourDiscountLabel", "Je korting")}</span>
                    <span className="product-discount-sum">€{discountAmount}</span>
                </div>}
                <div className="product-tax product-total-item">
                    <span>{LabelHelper.GetLabel(LabelCategory.Checkout, "Total", "Totaal exclusief BTW")}</span>
                    <span>€{totalSumExludeTax}</span>
                </div>
                <div className="product-tax product-total-item">
                    <span>{btwPercetage}% {LabelHelper.GetLabel(LabelCategory.Checkout, "BTWLabel", "BTW")}</span>
                    <span className="product-tax-total">€{totalTaxSum}</span>
                </div>
                <div className="product-total-item">
                    <span className="product-total-title">{LabelHelper.GetLabel(LabelCategory.Checkout, "TotalBTW", "Totaal inclusief BTW")}</span>
                    <span className="product-total-sum">€{totalSum}</span>
                </div>
            </div>
            {withCouponCode && (
                <React.Fragment>
                    <div className="row">
                        <div className="col">
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className={`${bc}__couponcode`}>
                            <div className="col-medium-5">
                                <InputField
                                    className={cn(!isCouponCodeValid && "invalid-input")}
                                    name="CouponCode"
                                    type="text"
                                    label={LabelHelper.GetLabel(LabelCategory.Checkout, "InputCouponCode", "Couponcode (optioneel)")}
                                    withButton
                                    btnLabel={LabelHelper.GetLabel(LabelCategory.Checkout, "CheckCouponCodeBtn", ">")}
                                    btnType="button"
                                    isLoading={isLoading}
                                    onClick={() => { checkCouponCodeAsync() }}
                                    nativeValue={couponCode}
                                    nativeOnChange={e => { setCouponCode(e.target.value); }}
                                />
                            </div>
                        </div>
                    </div>
                    </React.Fragment>
                )}
        </div>
        )
};
