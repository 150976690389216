import React from "react";
import cn from "classnames";
import Button from "../../../kbc-react-app/components/Button";

const bc = "old-report-versions-dropdown";

interface IOption {
    reportName: string;
    link: string;
    lang: string;
    dateString: string;
}

interface IProps {
    triggerLabel: string;
    fullReportsLabel: string;
    shortReportsLabel: string;
    fullReports: IOption[];
    shortReports: IOption[];
}

export const OldReportVersionsDropdown: React.FunctionComponent<IProps> = props => {
    const [isOpen, setOpen] = React.useState(false);
    const isBothReportTypes = React.useMemo(
        () => props.fullReports.length && props.shortReports.length,
        [props.shortReports.length, props.fullReports.length]
    );

    React.useEffect(() => {
        document.addEventListener("click", (e: any) => {
            if (!e.target.closest(`.${bc}`)) {
                setOpen(false)
            }
        });
    }, []);

    const toggleOpen = () => {
        setOpen(!isOpen);
    };
    const renderBothReportTypes = () => (
        <>
            <div className={`${bc}__group-option`}>
                <div className="group-option-name">{props.fullReportsLabel}</div>
                {props.fullReports.map(report => (
                    <a target="_blank" className={`${bc}__option`} key={report.link} onClick={() => setOpen(false)}
                       href={report.link}>{report.reportName} [{report.lang}] {report.dateString}</a>
                ))}
            </div>
            <div className={`${bc}__group-option`}>
                <div className="group-option-name">{props.shortReportsLabel}</div>
                {props.shortReports.map(report => (
                    <a target="_blank" className={`${bc}__option`} key={report.link} onClick={() => setOpen(false)}
                       href={report.link}>{report.reportName} [{report.lang}] {report.dateString}</a>
                ))}
            </div>
        </>
    );
    const renderSingleReportType = () => [...props.fullReports, ...props.shortReports].map(report => (
        <a target="_blank" className={`${bc}__option`} key={report.link} onClick={() => setOpen(false)}
           href={report.link}>{report.reportName} [{report.lang}] {report.dateString}</a>
    ))

    return (
        <div className={cn(bc, isOpen && `${bc}--open`)} onClick={toggleOpen}>
            <div className={`${bc}__trigger`}>
                <Button
                    label={props.triggerLabel}
                    type="button"
                    secondary
                    fullWidth/>
            </div>
            <div className={`${bc}__options`}>
                {isBothReportTypes ? renderBothReportTypes() : renderSingleReportType()}
            </div>
        </div>
    )
};
OldReportVersionsDropdown.defaultProps = {
    fullReports: [],
    shortReports: []
};
