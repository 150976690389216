import * as React from "react";
import cn from "classnames";

export const bc = "company-score";

type DisableEffect = "blur" | "smooth";

export interface ICompanyScoreProps {
    className?: string;
    disableEffect?: DisableEffect;
    disabled?: boolean;
}

const getDisableClasses  = (effect: DisableEffect) => {
    if (effect === "blur") {
        return "disabled disabled--blur"
    }
    return "disabled disabled--smooth"
};

export const CompanyScore: React.FunctionComponent<React.PropsWithChildren<ICompanyScoreProps>> = props => {
    return (
        <div className={cn(bc, props.className, props.disabled && getDisableClasses(props.disableEffect))}>
            {props.children}
        </div>
    )
};

CompanyScore.defaultProps = {
    disableEffect: "smooth"
};
