import * as React from "react";
import cn from "classnames";
import Card, { CardBody, CardHeader } from "../../../react-shared/components/Card";
import { LabelCategory, LabelHelper } from "../../helpers/LabelHelper";
import { Tooltip } from "../Tooltip";
import moneyFormatter from "../../helpers/moneyFormatter";
import { CompanyScore, ICompanyScoreProps } from "./CompanyScore";

const bc = "company-score";

export interface CompanyCreditLimitScoreProps extends ICompanyScoreProps {
    labelCategory: LabelCategory;
    value: number;
    currencyCode: string;
    isDefault?: boolean;
    explanation?: string;
}

const CurrencyCodeDict = {
    "EUR": "€"
};

const CompanyCreditLimitScore: React.FunctionComponent<CompanyCreditLimitScoreProps> = ({
                                                                                            value,
                                                                                            currencyCode,
                                                                                            isDefault,
                                                                                            explanation,
                                                                                            labelCategory,
                                                                                            ...props
                                                                                        }) => {
    const renderDefaultMaxCreditValue = () => "€ XXXXX";

    return (
        <CompanyScore {...props} className={cn(bc, "credit-limit", props.className)}>
            <Card>
                <CardHeader>
                    <div className="score-header">
                        <h3>
                            {LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CreditLimitCardHeader", "Kredietlimiet")}
                        </h3>
                        {explanation ? <Tooltip text={explanation} className="explanation-tooltip"/> : null}
                    </div>
                </CardHeader>
                <CardBody contentCenter>
                    <div className="credit-amount">
                        {
                            isDefault
                                ? renderDefaultMaxCreditValue()
                                : `${CurrencyCodeDict[currencyCode] || CurrencyCodeDict.EUR} ${moneyFormatter(value) || "-"}`

                        }
                    </div>
                    <div>{LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_CreditLimitCard_RecommendedLimitText", "De geadviseerde maximale kredietlimiet")}</div>
                </CardBody>
            </Card>
        </CompanyScore>
    )
};

export default CompanyCreditLimitScore;
