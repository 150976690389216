import * as React from "react";
import cn from "classnames";

const bc = "company-profile-score-card";

export interface ICardProps {
    disabled?: boolean;
}

interface ICardHeaderProps {
    withInfo?: boolean;
    infoText?: string;
}

interface ICardBodyProps {
    contentCenter?: boolean;
    className?: string;
}

interface ICardFooterProps {
    contentCenter?: boolean;
}

export const CardHeader: React.FunctionComponent<React.PropsWithChildren<ICardHeaderProps>> = ({ children }) => {
    return (
        <div className={`${bc}__header`}>
            {children}
        </div>
    )
};

export const CardBody: React.FunctionComponent<React.PropsWithChildren<ICardBodyProps>> = ({ contentCenter, className, children }) => {
    return (
        <div className={cn(`${bc}__body`, contentCenter && `${bc}__body--center`, className && className)}>
            {children}
        </div>
    )
};

export const CardFooter: React.FunctionComponent<React.PropsWithChildren<ICardFooterProps>> = ({ contentCenter, children }) => {
    return (
        <div className={cn(`${bc}__footer`, contentCenter && `${bc}__footer--center`)}>
            {children}
        </div>
    )
};


const Card: React.FunctionComponent<React.PropsWithChildren<ICardProps>> = ({
                                                       disabled,
                                                       children
                                                   }) => {
    return (
        <div className={cn(bc, disabled && "disabled")}>
            {children}
        </div>
    )
};

export default Card;
