import * as React from "react";
import { LabelCategory, LabelHelper } from "../../react-shared/helpers/LabelHelper";
import cn from "classnames";
import * as ReactDOM from "react-dom";
import { usePopperTooltip } from "react-popper-tooltip";

const bc = "product-item-card";

interface IProps {
    reportAmount: number;
    price: number;
    favorite?: boolean;
    isSelected?: boolean;
    onSelect?: () => void;
    asRadio?: boolean;
    tooltipText?: string;
}

export const ProductItemCard: React.FunctionComponent<IProps> = props => {
    const { reportAmount, price, favorite, isSelected, onSelect, asRadio, tooltipText } = props;
    const pricePerReport = (price / reportAmount).toFixed(2);
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible
    } = usePopperTooltip({ interactive: true, placement: "top-start" });

    return (
        <div 
            className={cn(bc, asRadio && `${bc}--radio`)} 
            onClick={() => asRadio ? onSelect() : () => {}}
            ref={setTriggerRef}
        >
            {favorite && <span
                className="product-item-card__favorite-label">{LabelHelper.GetLabel(LabelCategory.HomeReportCards, "favorite-label-text", "MEEST GEKOZEN", "La plus populaire")}</span>}
            {asRadio ? (
                <div className="product-item-card__radio">
                    <label className="g-radio g-radio--circle">
                        <input className="g-radio__input" type="radio" name="product" checked={isSelected}/>
                        <span className="g-radio__box"/>
                    </label>
                </div>
            ) : null}
            <div className="product-item-card__product-amount">
                {LabelHelper.GetLabel(LabelCategory.HomeReportCards, `${reportAmount}-rapport-title`, `${reportAmount} rapport`, `${reportAmount} rapport`)}
            </div>
            <div className="product-item-card__price">€{price}</div>
            <div className="product-item-card__price-per-report">
                {LabelHelper.GetLabel(LabelCategory.HomeReportCards, `body-title-sub-${reportAmount}`, `Los verkrijgbaar voor €{{price_per_report}}`, `€{{price_per_report}} par rapport`)?.replace("{{price_per_report}}", pricePerReport)}
            </div>

            {tooltipText && visible && ReactDOM.createPortal(
                <div
                    ref={setTooltipRef}
                    {...getTooltipProps({ className: 'tooltip-container' })}
                >
                    <div dangerouslySetInnerHTML={{ __html: tooltipText }} />
                    <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                </div>,
                document.body
            )}
        </div>
    )
};

ProductItemCard.defaultProps = {
    onSelect: () => {
    },
    reportAmount: 0,
    price: 0,
    isSelected: false,
    favorite: false
};
