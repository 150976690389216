import * as React from "react";

interface IProps {
    text: string;
}

const bc = "r__error-tooltip";

const ErrorTooltip: React.FunctionComponent<IProps> = ({ text }) => (
    <div className={`${bc} notification__validation--error`}>
        {text}
    </div>
);

export default ErrorTooltip;