import * as React from "react";
import { LabelCategory, LabelHelper } from "../../react-shared/helpers/LabelHelper";
import { Dropdown, IDropdownOption } from "../common/Dropdown";
import Button from "../common/Button";

const bc = "g-language-dropdown";

const OPTIONS = [
    {
        text: LabelHelper.GetLabel(LabelCategory.Checkout, "ReportLanguageDropdownOption_EN", "Engelse PDF"),
        value: "en"
    },
    {
        text: LabelHelper.GetLabel(LabelCategory.Checkout, "ReportLanguageDropdownOption_NL", "Nederlandse PDF"),
        value: "nl"
    },
    {
        text: LabelHelper.GetLabel(LabelCategory.Checkout, "ReportLanguageDropdownOption_FR", "Franse PDF"),
        value: "fr"
    }
];

interface IReportLanguageDropdownProps {
    action: string;
    name: string;
    isMultipleReports: boolean;
}


export const ReportLanguageDropdown: React.FunctionComponent<IReportLanguageDropdownProps> = ({
                                                                                                  action,
                                                                                                  name,
                                                                                                  isMultipleReports
                                                                                              }) => {
    const [selectedOption, setSelectedOption] = React.useState(OPTIONS[0]);
    const [isLoading, setLoading] = React.useState(false);

    const onSelect = (option: IDropdownOption) => {
        setSelectedOption(option);
        setLoading(true);
    };
    const downloadButtonText = isMultipleReports
        ? LabelHelper.GetLabel(LabelCategory.Checkout, "ReportLanguageDropdown_ButtonText_MultipleReports", "Download rapporten")
        : LabelHelper.GetLabel(LabelCategory.Checkout, "ReportLanguageDropdown_ButtonText", "Download Rapport");

    return (
        <form method="get" action={action} style={{ display: "flex", justifyContent: "center" }}>
            <input type="hidden" name={name} value={selectedOption.value}/>
            <Dropdown
                className={bc}
                trigger={isLoading ? () => <Button label="" isLoading={isLoading} type="button" /> : downloadButtonText}
                options={OPTIONS}
                onSelect={onSelect}
                disabled={isLoading}
            />
        </form>
    )
};
