import { getTopLevelDomain } from "./locationHelper";

const DEFAULT_COUNTRY = "nl";

export const DomainToCountryMap = {
    be: "be",
    uk: "gb",
    nl: "nl"
};

const getCountry = () => localStorage.getItem("country_code") || DomainToCountryMap[getTopLevelDomain()] || DEFAULT_COUNTRY;

const getCountryBasedOnDomain = () => DomainToCountryMap[getTopLevelDomain()] || DEFAULT_COUNTRY;

const cacheCountry = value => localStorage.setItem("country_code", value);

export {
    getCountry,
    cacheCountry,
    getCountryBasedOnDomain
}
