import * as React from "react";
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';


interface IProps {
    environment: string;
    paymentReference: string;
    clientKey: string;
    sessionId: string;
    sessionData: string;
    confirmEndpoint: string;
    onBack: () => void;
}

const PaymentDropIn: React.FunctionComponent<IProps> = (props) => {

    React.useEffect(() => {

        const configuration = {
            environment: props.environment, // Change to 'live' for the live environment.
            clientKey: props.clientKey, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
            analytics: {
                enabled: true // Set to false to not send analytics data to Adyen.
            },
            session: {
                id: props.sessionId, // Unique identifier for the payment session.
                sessionData: props.sessionData // The payment session data.
            },
            onPaymentCompleted: (result, component) => {
                if (result.resultCode == "Authorised") {
                    window.location.href = props.confirmEndpoint;
                }
                console.info(result, component);
            },
            onError: (error, component) => {
                console.error(error.name, error.message, error.stack, component);
            },
            // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
            // For example, this is 3D Secure configuration for cards:
            paymentMethodsConfiguration: {
                card: {
                    hasHolderName: true,
                    holderNameRequired: true,
                    billingAddressRequired: false
                }
            }
        };

        // Create an instance of AdyenCheckout using the configuration object.
        const checkout = async () => await AdyenCheckout(configuration);

        // Create an instance of Drop-in and mount it to the container you created.
        const dropinComponent = checkout().then(value => value.create('dropin').mount('#dropin-container'));

    }, []);



    return (<div id="dropin-container"></div>);
};

export { PaymentDropIn };
