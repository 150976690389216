import * as React from "react";
import cn from "classnames";
// @ts-ignore
import { Field } from 'formik';
import ErrorTooltip from "../ErrorTooltip";
import Button from "../../../../common/Button";

const bc = "r_input-field";

interface IProps {
    name: string;
    placeholder?: string;
    type?: string;
    label: string;
    readonly?: boolean;
    errors?: Record<string, any>;
    className?: string;
    withButton?: boolean;
    btnLabel?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    btnType?: "button" | "submit",
    isLoading?: boolean;
    autoComplete?: boolean;
    disabled?: boolean;
    nativeValue?: string;
    nativeOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FunctionComponent<any> = props => {
    const value = props.field?.value ?? props?.nativeValue ?? "";
    return (
        <div className={cn(bc, "form__item--new relative", value && "has-value", props.className && props.className, props.withButton && "with-button")}>
            <input
                id={props.field.name}
                className="form__field--floating ui-autocomplete-input"
                {...props.field}
                {...(!props.autoComplete ? { autoComplete: "off" } : {})}
                value={value}
                placeholder={props.placeholder}
                type={props.type}
                readOnly={props.readonly}
                disabled={props.disabled}
                {...(props.nativeOnChange ? { onChange: props.nativeOnChange } : {})}
            />
            <label htmlFor={props.field.name}>{props.label}</label>
            {props.errors[props.field.name] && props.form.touched[props.field.name] && <ErrorTooltip text={props.errors[props.field.name]} />}
            {props.withButton && (
                <Button isLoading={props.isLoading} label={props.btnLabel} type={props.btnType} secondary onClick={props.onClick} />
            )}
        </div>
    )
}

const InputField: React.FunctionComponent<IProps> = ({
    type = "text",
    name,
    label,
    placeholder,
    errors = {},
    className,
    withButton,
    btnLabel,
    onClick = (e: React.MouseEvent<HTMLElement>) => { },
    btnType = "button",
    readonly,
    isLoading,
    autoComplete = true,
    disabled,
    nativeValue = "",
    nativeOnChange
}) => (
    <Field
            type={type}
            name={name}
            label={label}
            component={Input}
            placeholder={placeholder}
            errors={errors}
            className={className}
            withButton={withButton}
            btnLabel={btnLabel}
            onClick={onClick}
            btnType={btnType}
            readonly={readonly}
            isLoading={isLoading}
            autoComplete={autoComplete}
            disabled={disabled}
            nativeValue={nativeValue}
            nativeOnChange={nativeOnChange}
    />
    )

export default InputField;
