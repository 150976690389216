import * as React from "react";
import cn from "classnames";

export interface IDropdownOption {
    text: string;
    value: string;
}

type TriggerDescriptionRenderFunc = () => JSX.Element;

type Trigger = string | TriggerDescriptionRenderFunc;

const bc = "g-dropdown";

interface IDropdownProps {
    className?: string;
    options: IDropdownOption[];
    onSelect?: (option: IDropdownOption) => void;
    trigger: Trigger;
    disabled?: boolean;
}

export const Dropdown: React.FunctionComponent<IDropdownProps> = props => {
    const [isOpen, setOpen] = React.useState(false);
    const toggleOpen = () => {
        if (!props.disabled) {
            setOpen(!isOpen);
        }
    };

    React.useEffect(() => {
        const outsideClickHandler = function (e) {
            const dropdown = document.querySelector(`.${bc}`);
            // @ts-ignore
            if (!dropdown.contains(e.target)) {
                setOpen(false);
            }
        };
        window.addEventListener('click', outsideClickHandler);

        return () => {
            window.removeEventListener('click', outsideClickHandler);
        };
    }, []);

    React.useEffect(() => {
        if (props.disabled && isOpen) {
            setOpen(false);
        }
    }, [props.disabled])

    return (
        <div className={cn(bc, isOpen && `${bc}--open`, props.disabled && `${bc}--disabled`, props.className && props.className)} onClick={toggleOpen}>
            <div className={`${bc}__trigger`}>
                {typeof props.trigger === "string" ? props.trigger : props.trigger()}
            </div>
            <div className={`${bc}__options`}>
                {props.options.map(option => (
                    <button className="dropdown-option" key={option.value} onClick={() => props.onSelect(option)}>
                        {option.text}
                    </button>
                ))}
            </div>
        </div>
    )
};

Dropdown.defaultProps = {
    options: [],
    onSelect: () => {}
};
