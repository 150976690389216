import * as React from "react";
import { LabelCategory, LabelHelper } from "../../react-shared/helpers/LabelHelper";
import CompanyGradeChart, { CompanyGrade } from "../../react-shared/components/CompanyProfileScore/CompanyGradeChart";
import { ConditionsContainer, ContentUnlocker, UnlockableContent } from "../components/ContentUnlocker";
import Checkbox from "../components/common/form/fields/Checkbox";
import { Formik } from "formik";
import { CompanyScore } from "../../react-shared/components/CompanyProfileScore/CompanyScore";
import cn from "classnames";
import Card, { CardBody, CardHeader } from "../../react-shared/components/Card";
import { Tooltip } from "../../react-shared/components/Tooltip";

const bc = "company-profile-financial-situation";

interface GraydonRatingScoreProps {
    grade: CompanyGrade;
}

interface IProps {
    graydonRatingScore: GraydonRatingScoreProps;
    IsOpenProfile: boolean;
}

const explanationNL = `
<p>De indicatie van de financiële gezondheid is gratis. Het toont aan welk financieel risico je loopt als je met deze organisatie samenwerkt</p>
<ul style="padding-left: 20px;">
<li>Rood: Hoog tot zeer hoog risico</li>
<li>Oranje: Hoog tot matig risico</li>
<li>Geel: Matig risico</li>
<li>Lichtgroen: Matig tot laag risico</li>
<li>Donkergroen: Laag tot zeer laag risico</li>
</ul>
`;
const explanationFR = `
<p>L'indication de la santé financière est gratuite. Elle montre le risque financier que vous courez si vous travaillez avec cette organisation.</p>
<ul style="padding-left: 20px;">
<li>Rouge: risque élevé à très élevé</li>
<li>Orange: risque élevé à modéré</li>
<li>Jaune: risque modéré</li>
<li>Vert clair: risque modéré à faible</li>
<li>Vert foncé: risque faible à très faible</li>
</ul>
`;

const Conditions: React.FunctionComponent<any> = props => {
    return React.useMemo(() => {
        const agreementText = LabelHelper.GetLabel(LabelCategory.CompanyProfile, "AgreementToSeeFreeShortReport_Text", `Ik heb de <a href="https://graydongo.be/nl/algemenevoorwaarden-bedrijven" target="_blank">algemene voorwaarden</a> van GraydonGo gelezen en aanvaard. Ik verklaar expliciet een grondslag voor verwerking ( bv. gerechtvaardigd belang of toestemming) te hebben onder artikel 6. 1 AVG zoals vereist in de AVG.`, `Je confirme avoir lu et accepter <a href="https://graydongo.be/fr/conditionsgenerales-entreprises" target="_blank">les conditions générales</a> de GraydonGo. En vertu de l'article 6.1 du RGPD et comme requis par ce règlement, je déclare avoir une base de données pour le traitement d’informations (en relation avec un intérêt légitime ou par le consentement des intéressés).`);

        return (
            <Formik initialValues={{ agreement: false }} onSubmit={() => {
            }} className={bc}>
                {({ values: { agreement } }) => {
                    if (agreement) {
                        return props.unlock(true);
                    }
                    return <Checkbox name="agreement" label={agreementText} disableHtmlFor/>;
                }}
            </Formik>
        )
    }, [props.isLocked]);
};

export const CompanyFreeShortFinancialReport: React.FunctionComponent<IProps> = props => {
    const labelCategory = LabelCategory.CompanyProfile;
    const title = LabelHelper.GetLabel(LabelCategory.CompanyProfile, "GraydonRatingFreeScore_Title", "Gratis financiële gezondheidsindicatie", "Indication gratuite de la santé financière");
    const explanation = LabelHelper.GetLabel(LabelCategory.CompanyProfile, "GraydonRatingFreeScore_ExplanationTooltipText", explanationNL, explanationFR);

    return (
        <div className={bc}>
            <ContentUnlocker>
                <ConditionsContainer>
                    <Conditions/>
                </ConditionsContainer>
                <CompanyScore {...props} className={cn(`${bc}--graydon-rating`)}>
                    <Card>
                        <CardHeader>
                            <div className="score-header">
                                <h3>
                                    {title ?? LabelHelper.GetLabel(labelCategory, "CompanyProfilePage_GraydonRatingCard_HeaderText", "Graydon Rating")}
                                </h3>
                                {explanation ? <Tooltip text={explanation} className="explanation-tooltip"/> : null}
                            </div>
                        </CardHeader>
                        <UnlockableContent>
                            <CardBody contentCenter>
                                <CompanyGradeChart mode={props.IsOpenProfile ? "bar" : "half-pie-group"} 
                                                   labelCategory={labelCategory}
                                                   grade={props.graydonRatingScore.grade}
                                                   isDefault={false}/>
                            </CardBody>
                        </UnlockableContent>
                    </Card>
                </CompanyScore>
            </ContentUnlocker>
        </div>
    )
};
