import { localOriginName } from "../../react/constants";

const queryString = require("query-string");

const parseHostname = () => {
    return window.location.hostname.split(".");
};

const getTopLevelDomain = () => parseHostname().pop() === "localhost" ? "nl" : parseHostname().pop();

const getCurrentLanguagePath = () => {
    let pathParts = window.location.pathname.split('/');
    let currentLanguage = pathParts[1];
    if (currentLanguage.length == 2) {
        return currentLanguage;
    }
    return null;
}

const getHostnameWithProtocolAndLanguage = () => {
    let currentLanguage = getCurrentLanguagePath();

    // Only for dev env
    if (window.location.origin.includes(localOriginName)) {
        return `${window.location.origin}/${currentLanguage}`;
    }

    if (currentLanguage != null && currentLanguage.length != null) {
        return `${window.location.protocol}//${window.location.hostname}/${currentLanguage}`;
    } else {
        return `${window.location.protocol}//${window.location.hostname}`;
    }
}

const getHostnameWithProtocol = () => `${window.location.protocol}//${window.location.hostname}`;

const getProtocol = () => window.location.protocol;

const getPathname = () => window.location.pathname;

const goTo = (url) => {
    window.location.href = url;
};

const getHref = () => window.location.href;

const getHrefWithoutQueryString = () => getHref().split("?")[0];

const parseQueryParams = () => queryString.parse(window.location.search);

const toQueryParams = (params: Record<string, string>) => queryString.stringify(params);

const getLocation = () => window.location;

const isNLDomain = () => getTopLevelDomain().toLowerCase().includes("nl") || getTopLevelDomain().toLowerCase().includes("local");

const isBEDomain = () => getTopLevelDomain().toLowerCase().includes("be");

export {
    parseHostname,
    getTopLevelDomain,
    getHostnameWithProtocol,
    getCurrentLanguagePath,
    getHostnameWithProtocolAndLanguage,
    getProtocol,
    getPathname,
    goTo,
    getHrefWithoutQueryString,
    parseQueryParams,
    getHref,
    toQueryParams,
    getLocation,
    isBEDomain,
    isNLDomain
}
