import * as React from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import * as ReactDOM from "react-dom";
import cn from "classnames";

const bc = "g-tooltip";

interface ITooltipProps {
    text: string;
    className?: string;
    sign?: string;
}

export const Tooltip: React.FunctionComponent<ITooltipProps> = ({ text, className = "", sign = "?" }) => {
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible
    } = usePopperTooltip({ interactive: true, placement: "top-start", delayHide: 100 });

    const [isClicked, setIsClicked] = React.useState(false);

    return (
        <div className={cn(bc, className)} ref={setTriggerRef} {...visible && isClicked && { style: { pointerEvents: 'none' } }} onClick={() => setIsClicked(!visible) }>
            <div className={`${bc}__sign`}>{sign}</div>
            {visible && ReactDOM.createPortal(
                <div
                    ref={setTooltipRef}
                    {...getTooltipProps({ className: 'tooltip-container' })}
                >
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                    <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                </div>,
                document.body
            )}
        </div>
    )
};
