import { getHostnameWithProtocol } from "../../react-shared/helpers/locationHelper";
import { localOriginName } from "../constants";

const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
};

const formDataHeaders = {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
    "X-Requested-With": "XMLHttpRequest"
};

interface IOptions {
    url: string;
    method?: string;
    headers?: {};
    data?: {};
    asFormData?: boolean;
}

export default ({ url, method = "GET", headers = {}, data, asFormData }: IOptions) => {
    const definedHeaders = asFormData ? formDataHeaders : defaultHeaders;
    let requestData = {} as any;

    if (!asFormData) {
        requestData = data && typeof data === "object" ? JSON.stringify(data) : undefined;
    }

    if (asFormData) {
        requestData = new URLSearchParams();
        Object.keys(data).forEach(key => {
            requestData.append(key, data[key]);
        });
    }

    const requestOptions = {
        method,
        headers: {
            ...definedHeaders,
            ...headers
        },
        body: requestData,
    };

    return fetch(`${window.location.origin === localOriginName ? window.location.origin : getHostnameWithProtocol()}${url}`, requestOptions).then(res => {
        return res.json().catch(() => {
            if (res.redirected || (res.status > 300 && res.status < 305)) {
                window.location.href = res.url;
                return null;
            }
        });
    });
};
