import * as React from "react";
import cn from "classnames";

const bc = "spinner";

export interface ISpinnerProps {
    className?: string;
}

export const Spinner: React.FunctionComponent<ISpinnerProps> = props => {
    return <div className={cn(bc, props.className && props.className)}/>;
};
