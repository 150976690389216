import * as $ from 'jquery';
import { getHostnameWithProtocolAndLanguage } from "./locationHelper";


export enum LabelCategory {
    FollowCompany = "follow-company",
    CompanySearch = "company-search",
    HomeReportCards = "home-report-cards",
    Checkout = "checkout-page",
    KBCIntegration = "kbc-integration",
    CompanyProfile = "company-profile",
}

interface Label {
    key: string;
    defaultText: string
}

interface AddLabel extends Label {
    defaultFrenchBEText?: string;
    defaultNetherlandsBEText?: string;
    defaultEnglishText?: string;
}

export class LabelHelper {
    private static _labelsToSave: Map<LabelCategory, Label[]> = new Map<LabelCategory, Label[]>();
    private static _labelUpdateTimeout = null;
    private static _labels: Map<LabelCategory, Label[]> = new Map<LabelCategory, Label[]>();

    public static GetLabel(category: LabelCategory, key: string, defaultText: string, defaultFrenchBEText = "", defaultNetherlandsBEText = "", defaultEnglishText = ""): string {
        if (!this._labels.has(category)) {
            LabelHelper.SetLabelCategory(category);
        }

        const label = this._labels.get(category).find(x => x.key == key);

        if (label == null) {
            LabelHelper.CreateLabel(category, {
                key: key,
                defaultText: defaultText,
                defaultFrenchBEText: defaultFrenchBEText,
                defaultNetherlandsBEText: defaultNetherlandsBEText,
                defaultEnglishText: defaultEnglishText
            });
            return defaultText;
        }

        return label.defaultText;
    }

    public static get commonLabel() {
        return {
            exampleCompanyReportLink: LabelHelper.GetLabel(LabelCategory.KBCIntegration, "ExampleCompanyReport_Link", "/nl/example-company-report", "/fr/example-company-report", "/nl/example-company-report", "/documents/Example%20report%20EN%202021.pdf")
        }
    }

    private static CreateLabel(category: LabelCategory, label: AddLabel) {
        this._labels.get(category).push(label);

        if (!this._labelsToSave.has(category)) {
            this._labelsToSave.set(category, []);
        }
        this._labelsToSave.get(category).push(label);

        clearTimeout(this._labelUpdateTimeout);
        this._labelUpdateTimeout = setTimeout(() => {
            if (this._labelsToSave.size == 0) {
                return;
            }

            const labels = Object.assign({}, this._labelsToSave.get(category));
            this._labelsToSave.clear();

            const host = getHostnameWithProtocolAndLanguage();

            $.ajax({
                url: host + "/labels/" + category.toString(),
                data: { labels: labels },
                method: 'POST'
            });
        }, 2500);
    }

    private static SetLabelCategory(category: LabelCategory) {
        this._labels.set(category, []); // Prevent TypeError in next handlers
        const host = getHostnameWithProtocolAndLanguage();
        $.ajax({
            async: false,
            url: host + '/labels/' + category.toString(),
            method: 'GET',
            success: function (result) {
                this._labels.set(category, result.labels);
            }.bind(this)
        });
    }
}
