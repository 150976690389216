import * as React from "react";
import cn from "classnames";

interface IProps {
    className?: string;
    onClose?: () => void;
    withClose?: boolean;
    visible?: boolean;
    ctrled?: boolean;
}

const bc = "r_jumbotron";

const Jumbotron: React.FunctionComponent<React.PropsWithChildren<IProps>> = ({ className, onClose = () => { }, withClose, visible = true, ctrled, children }) => {
    const [isVisible, setVisible] = React.useState(visible);

    return isVisible && (
        <div className={bc}>
            {withClose && <div className="with-close" onClick={() => {
                !ctrled && setVisible(false);
                onClose();
            }}></div>}
            <div className={cn(className && className)}>
                {children}
            </div>
        </div>
        )
};

export default Jumbotron;
