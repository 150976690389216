import * as React from "react";
import cn from "classnames";
import { Spinner } from "./Spinner";

interface IProps {
    label: string;
    onClick?: () => void;
    wide?: boolean;
    secondary?: boolean;
    primary?: boolean;
    type: "submit" | "button";
    disabled?: boolean;
    blury?: boolean;
    rounded?: boolean;
    isLoading?: boolean;
}

const bc = "r_button";
const secondaryClass = "btn--secondary";
const primaryClass = "btn--primary";

const Button: React.FunctionComponent<IProps> = ({
    label, onClick = () => { },
    wide,
    secondary,
    type,
    primary,
    blury,
    rounded,
    disabled,
    isLoading
}) => (
        <div className={`${bc} form__buttons`}>
            <button
                className={cn(secondary && secondaryClass, primary && primaryClass, wide && "wide", blury && "blury", rounded && "rounded", isLoading && "loading")}
                onClick={onClick}
                type={type}
                disabled={disabled}
            >
                {isLoading && <Spinner />}
                <span style={{ visibility: isLoading ? "hidden" : "visible" }}>{label}</span>
        </button>
    </div>
);

export default Button;
